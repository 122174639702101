import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  CircularProgress,
  Backdrop,
  Checkbox,
} from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import MdEditor from "md-editor-rt";
import "md-editor-rt/lib/style.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { ThemeContext } from "src/context";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import httpClient from "src/libs/httpClient";
import uploadPhoto from "src/libs/uploadPhoto";
import { toast } from "sonner";
import deletePhoto from "src/libs/deletePhoto";
import DeleteIcon from "@mui/icons-material/Delete";

export default function PartnersDialog({ open, setOpen, data, getData }) {
  const { theme, bp } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");

  const [logo, setLogo] = useState([]);
  const [banner, setBanner] = useState([]);
  const [visibility, setVisibility] = useState(false);

  const [oldLogoURL, setOldLogoURL] = useState("");
  const [oldBannerURL, setOldBannerURL] = useState("");

  const [markdown, setMarkdown] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);

  const [showSocialLinksDialog, setShowSocialLinksDialog] = useState(false);

  useEffect(() => {
    if (data) {
      setName(data?.name);
      setSlug(data?.slug);

      setOldLogoURL(data?.logo);
      setOldBannerURL(data?.banner);

      setMarkdown(data?.markdown);
      setSocialLinks(data?.socialLinks);
      setVisibility(data?.isPublic);
    }
  }, [data]);

  const uploadLogo = async (id) => {
    setLoading(true);
    await uploadPhoto(
      logo[0],
      "partners/logos",
      {
        width: 512,
        height: 512,
      },
      `${slug}-logo`
    )
      .then((url) => {
        setLoading(false);

        httpClient()
          .put(`/admin/companyPartners/${id}`, { logo: url })
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const uploadBanner = async (id) => {
    setLoading(true);
    await uploadPhoto(banner[0], "partners/banners", {}, `${slug}-banner`)
      .then((url) => {
        setLoading(false);

        httpClient()
          .put(`/admin/companyPartners/${id}`, { banner: url })
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!name || !slug || !markdown) {
      toast("Please fill all the required fields", "error");
      setLoading(false);
      return;
    }
    console.log("data: ", socialLinks);
    const dataToPost = {
      name,
      slug,
      markdown: markdown,
      socialLinks,
      isPublic: visibility,
    };

    setLoading(false);

    if (data) {
      httpClient()
        .put(`/admin/companyPartners/${data?._id}`, dataToPost)
        .then((res) => {
          setLoading(false);

          setName("");
          setSlug("");

          setMarkdown("");
          setSocialLinks([]);
          setVisibility(false);

          if (logo?.length > 0) {
            uploadLogo(data?._id);
          }

          if (banner?.length > 0) {
            uploadBanner(data?._id);
          }

          setOpen(false);
          getData();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else {
      httpClient()
        .post("/admin/companyPartners", dataToPost)
        .then((res) => {
          setLoading(false);

          setName("");
          setSlug("");

          setMarkdown("");
          setSocialLinks([]);
          setVisibility(false);

          if (logo?.length > 0) {
            uploadLogo(res?.data?._id);
          }

          if (banner?.length > 0) {
            uploadBanner(res?.data?._id);
          }

          setOpen(false);
          getData();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const deleteOldImage = (e) => {
    setLoading(true);

    deletePhoto(e).then((res) => {
      httpClient()
        .put(
          `/admin/companyPartners/${data._id}`,
          e === oldBannerURL ? { banner: "" } : { logo: "" }
        )
        .then((res) => {
          console.log(res);

          if (e === oldBannerURL) {
            setBanner([]);
            setOldBannerURL("");
          } else {
            setLogo([]);
            setOldLogoURL("");
          }

          setLoading(false);

          toast("Image updated", { type: "success" });
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);

          setLoading(false);
          toast(err.response.data.message || "Failed to update testimonial", {
            type: "error",
          });
        });
      // } else if (e === oldCompanyLogoURL) {
      //   httpClient()
      //     .put(`/admin/companyPartners/${data._id}`, {
      //       company: { logo: "" },
      //     })
      //     .then((res) => {
      //       console.log(res);

      //       setOldCompanyLogoURL("");
      //       setCompanyLogo([]);

      //       setLoading(false);
      //       toast("Testimonial updated", { type: "success" });
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       setOpen(false);
      //       setLoading(false);

      //       toast(err.response.data.message || "Failed to update testimonial", {
      //         type: "error",
      //       });
      //     });
      // }
    });
  };
  const handleVisibility = () => {
    setVisibility((e) => !e);
  };
  return (
    <>
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 9999999999999999999,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialogContent-root": {
            overflowY: "unset",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "100%",

            backgroundColor: theme.backgroundColor,
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                sx={{
                  width: 40,

                  color: theme.buttonColor,
                  backgroundColor: theme.buttonColor + "22",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "100%",

                height: "100%",

                // backgroundColor: "red",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
                paddingBlock: 4,
              }}
            >
              {" "}
              <Typography
                variant="h3"
                sx={{ color: theme.textColor, fontWeight: 600 }}
              >
                Add partners information:
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 1200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: bp?.md ? "column" : "row",
                    gap: 2,
                    // paddingBlock: 2,
                  }}
                >
                  <PDInput
                    label="Partner Name"
                    type="text"
                    placeholder="Enter partner name.."
                    required
                    sx={{ backgroundColor: theme.ui }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <PDInput
                    label="Partner Slug"
                    type="text"
                    placeholder="Enter partner slug.."
                    required
                    sx={{ backgroundColor: theme.ui }}
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 2,
                    flexDirection: bp?.md ? "column" : "row",
                  }}
                >
                  <PDDropzone
                    value={logo}
                    setValue={setLogo}
                    maxFiles={1}
                    bp={bp}
                    label="Logo"
                    oldFiles={oldLogoURL?.length > 0 ? [oldLogoURL] : []}
                    fileToDelete={(e) => deleteOldImage(e)}
                  />

                  <PDDropzone
                    value={banner}
                    setValue={setBanner}
                    maxFiles={1}
                    bp={bp}
                    label="Banner"
                    oldFiles={oldBannerURL?.length > 0 ? [oldBannerURL] : []}
                    fileToDelete={(e) => deleteOldImage(e)}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<AddRoundedIcon />}
                    onClick={() => setShowSocialLinksDialog((e) => !e)}
                  >
                    Add Social Links
                  </Button>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                      mb: 0.5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: theme.textColor2, fontWeight: 500 }}
                    >
                      Markdown:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 600,
                        color: "#bc002d",
                      }}
                    >
                      *
                    </Typography>
                  </Box>

                  <MdEditor
                    style={{
                      backgroundColor: theme.ui,
                      height: bp?.sm ? 200 : 250,
                      width: "100%",

                      borderRadius: 4,
                      border: "1px solid " + theme.borderColor,
                      color: theme.textColor2,
                      fontSize: 14,
                    }}
                    toolbars={[]}
                    language="en-US"
                    preview={false}
                    footers={null}
                    modelValue={markdown}
                    onChange={setMarkdown}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    // gap: 0.5,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: theme.textColor2, fontWeight: 500 }}
                  >
                    Is Public:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // gap: 0.5,
                      marginLeft: "-11px",
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={visibility}
                      onClick={handleVisibility}
                      color="primary"
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: visibility
                          ? theme.buttonColor
                          : theme.textColor3,
                      }}
                    >
                      {visibility ? "Visible to public" : "Hidden from public"}
                    </Typography>
                  </Box>
                </Box>
                <PDButton
                  sx={{ maxWidth: 150 }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </PDButton>
              </Box>
            </Box>{" "}
          </Box>
        </DialogContent>
      </Dialog>
      <SocialLinksDialog
        open={showSocialLinksDialog}
        setOpen={setShowSocialLinksDialog}
        socialLinks={socialLinks}
        setSocialLinks={setSocialLinks}
      />
    </>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SocialLinksDialog = ({ open, setOpen, socialLinks, setSocialLinks }) => {
  const { theme } = useContext(ThemeContext);
  const [socialLinkName, setSocialLinkName] = useState("");
  const [socialLinkUrl, setSocialLinkUrl] = useState("");
  // useEffect(() => {
  //   if (open && socialLinks) {
  //   }
  // }, [open, socialLinks]);

  const onAdd = (e) => {
    e.preventDefault();
    if (socialLinkName === "" || socialLinkUrl === "") {
      return toast("Please fill all the fields", { type: "error" });
    }
    setSocialLinks([
      ...socialLinks,
      { key: socialLinkName, value: socialLinkUrl },
    ]);
    setSocialLinkName("");
    setSocialLinkUrl("");
    // setOpen(false);
  };
  const handleDelete = (index) => {
    setSocialLinks(socialLinks.filter((item, i) => i !== index));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      sx={{
        "& .MuiDialog-container": {
          backgroundImage: "none",
          "& .MuiPaper-root": {
            backgroundImage: "none",
            backgroundColor: theme.backgroundColor,
            // width: "100%",
            maxWidth: "500px",
          },
        },
      }}
      //

      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{
          color: theme.textColor,
          // px: 2,
          // pt: 2,
          // pb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Social Links
        <IconButton onClick={() => setOpen((e) => !e)}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          px: 3,
          pb: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <form
          onSubmit={onAdd}
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "flex-end",
            // justifyContent: "space-between",
          }}
        >
          <PDInput
            value={socialLinkName}
            type="text"
            label="Name"
            placeholder="ex. Linkedin, Twitter, etc."
            onChange={(e) => setSocialLinkName(e.target.value)}
          />
          <PDInput
            value={socialLinkUrl}
            type="url"
            label="Url"
            placeholder="ex. https://example.com/jutsu"
            onChange={(e) => setSocialLinkUrl(e.target.value)}
          />
          <IconButton
            type="submit"
            sx={{
              color: theme.buttonColor,
              backgroundColor: theme.buttonColor + "22",
            }}
          >
            <AddRoundedIcon />
          </IconButton>
        </form>

        {socialLinks?.length > 0 && (
          <Box sx={{ backgroundColor: theme.ui, padding: 1, borderRadius: 2 }}>
            {socialLinks.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // paddingLeft: 0.5,
                  }}
                >
                  {/* <PDInput
                value={item?.key}
                readOnly
                label="Name"
                placeholder="Name"
                // onChange={(e) => setSocialLinkName(e.target.value)}
              />
              <PDInput
                value={item?.value}
                readOnly
                label="Url"
                placeholder="Url"
                // onChange={(e) => setSocialLinkUrl(e.target.value)}
              /> */}
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.textColor,
                      fontWeight: 400,
                    }}
                  >
                    {index + 1}:{" "}
                    <span style={{ color: theme.textColor2 }}>
                      {item?.key}:
                    </span>{" "}
                    <span style={{ color: theme.textColor3 }}>
                      {item?.value}
                    </span>
                  </Typography>

                  <IconButton onClick={() => handleDelete(index)}>
                    <DeleteIcon
                      fontSize="small"
                      sx={{
                        display: "block",
                        cursor: "pointer",
                        color: theme.accent.red,
                      }}
                    />
                  </IconButton>
                </Box>
              );
            })}
          </Box>
        )}
      </DialogContent>

      {/* <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        ></Box>
      </DialogActions> */}
    </Dialog>
  );
};
