import { Box } from "@mui/material";
import CPanelContainer from "src/components/CPanelContainer";
import PDButton from "src/components/_pdStyle/PDButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PDInput from "src/components/_pdStyle/PDInput";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import PartnersDialog from "src/dialogs/PartnersDialog";
import httpClient from "src/libs/httpClient";
import PDPagiation from "src/components/_pdStyle/PDPagiation";
import PartnersTable from "./_components/PartnersTable";
import { toast } from "sonner";

export default function PartnersPage() {
  const [search, setSearch] = useState("");

  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [partners, setPartners] = useState([]);
  const [hasChangedPosition, setHasChangedPosition] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [page, perPage]);

  const getData = () => {
    httpClient()
      .get("/admin/companyPartners")
      .then((res) => {
        let data = res?.data || [];

        if (res?.data.length > 0) {
          while (res?.data.length < perPage) {
            data.push({ empty: true });
          }
        }

        setPartners(data);
        setPageCount(Math.ceil(res.data?.count / perPage));
        setTotalCount(res.data?.count);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handlePositionUpdate = () => {
    toast("Updating position...", { type: "info" });
    setLoading(true);
    let data = partners.map((partner, index) => {
      return {
        _id: partner?._id,
        position: index,
      };
    });

    httpClient()
      .post("/admin/companyPartners/updatePosition", data)
      .then((res) => {
        setHasChangedPosition(false);
        setLoading(false);
        toast("Position updated successfully", { type: "success" });
      })
      .catch((err) => {
        setLoading(false);
        toast("Failed to update position", { type: "error" });
      });
  };

  return (
    <CPanelContainer title={`Partners`} sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <PDInput
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />
        <PDButton
          sx={{ maxWidth: 150 }}
          startIcon={<AddRoundedIcon />}
          onClick={() => setOpen((e) => !e)}
        >
          Add Partner
        </PDButton>
        {hasChangedPosition && (
          <PDButton
            sx={{ maxWidth: 150, backgroundColor: "red" }}
            startIcon={<TableRowsRoundedIcon />}
            onClick={handlePositionUpdate}
          >
            Update Position
          </PDButton>
        )}
      </Box>

      <PartnersTable
        partners={partners}
        getData={getData}
        loading={loading}
        perPage={perPage}
        setPartners={setPartners}
        setHasChangedPosition={setHasChangedPosition}
      />

      <PDPagiation
        pageCount={pageCount}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />

      <PartnersDialog
        open={open}
        setOpen={setOpen}
        getData={getData}
        loading={loading}
        setLoading={setLoading}
      />
    </CPanelContainer>
  );
}
