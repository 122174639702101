import { ThemeContext } from "src/context";
import { useContext, useEffect, useState } from "react";

import {
  TextField,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import CPanelContainer from "src/components/CPanelContainer";

import { Bar, Line } from "react-chartjs-2";
import httpClient from "src/libs/httpClient";
import accumulateTotals from "src/libs/accumulateTotals";
import InfoCard from "src/components/InfoCard";
import moment from "moment";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Monthly User Analysis",
    },
  },
};

export default function MonthlyUserAnalysesPage() {
  const { theme, bp } = useContext(ThemeContext);

  const [labels, setLabels] = useState([]);
  const [monthSelect, setMonthSelect] = useState(moment().format("YYYY-MM"));
  const [data, setData] = useState([]);

  const lineData = {
    labels,
    datasets: [
      {
        label: "Monthly User Analysis",
        data: accumulateTotals(data),
        borderColor: "#0d6efd",
        backgroundColor: "#0d6efd66",
      },
    ],
  };

  const barData = {
    labels,
    datasets: [
      {
        label: "Monthly User Analysis",
        data: data.map((item) => item.total),
        borderColor: "#ff004e",
        backgroundColor: "#ff004e66",
      },
    ],
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async (year, month) => {
    httpClient()
      .get(
        `/admin/analysis/users/range-aggregation/${
          year || new Date().getFullYear()
        }/${month || new Date().getMonth() + 1}`
      )
      .then((res) => {
        setData(res.data);
        const labels = res?.data?.map((item) => {
          return item?.day;
        });
        setLabels(labels);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPanelContainer title="Monthly Users Analysis" sx={{ p: 1 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: 2,
        }}
      >
        <InfoCard
          title="Total Users"
          subTitle="Total number of users created in the selected month"
          value={(
            data.reduce((acc, item) => acc + item.total, 0) || 0
          ).toLocaleString()}
        />

        <Box
          sx={{
            backgroundColor: theme.ui,
            color: theme.textColor,
            padding: 2,
            borderRadius: 2,
            gap: 1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            for="monthYear"
            variant="h6"
            sx={{ color: theme.textColor3, fontWeight: 500 }}
          >
            Select a (month and year):
          </Typography>

          <TextField
            type="month"
            id="monthYear"
            name="monthYear"
            value={monthSelect}
            onChange={(e) => {
              const [year, month] = e.target.value.split("-");
              console.log(e.target.value);
              setMonthSelect(e.target.value);
              getData(year, month);
            }}
            sx={{
              "*": {
                color: theme.textColor,
              },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: bp?.md ? "1fr" : "1fr 1fr",
          gap: 2,
        }}
      >
        <Box>
          <Line
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={lineData}
          />
        </Box>

        <Box>
          <Bar
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={barData}
          />
        </Box>
      </Box>

      <Box>
        <TableContainer
          sx={{
            backgroundColor: theme.ui,
            color: theme.textColor,
            borderRadius: 2,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: theme.textColor }}>Date</TableCell>
                <TableCell sx={{ color: theme.textColor }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...data].reverse()?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: theme.textColor + "11",
                    },
                  }}
                >
                  <TableCell sx={{ border: "none", color: theme.textColor2 }}>
                    {item.day}
                  </TableCell>
                  <TableCell sx={{ border: "none", color: theme.textColor2 }}>
                    {item.total.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CPanelContainer>
  );
}
