import { useContext, useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";

import httpClient from "src/libs/httpClient";

import { ThemeContext } from "src/context";
import CPanelContainer from "src/components/CPanelContainer";
import InfoCard from "src/components/InfoCard";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Jutsu's Monthly Component Analysis",
    },
  },
};

const labels = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24,
];

export default function DailyComponentAnalysesPage() {
  const { theme, bp } = useContext(ThemeContext);

  const [monthSelect, setMonthSelect] = useState(
    `${
      new Date().getFullYear() < 10
        ? `0${new Date().getFullYear()}`
        : new Date().getFullYear()
    }-${
      new Date().getMonth() < 10
        ? `0${new Date().getMonth()}`
        : new Date().getMonth()
    }-${
      new Date().getDate() < 10
        ? `0${new Date().getDate()}`
        : new Date().getDate()
    }`
  );

  const [label1, setLabel1] = useState();
  const [label2, setLabel2] = useState();
  const [label3, setLabel3] = useState();
  const [label4, setLabel4] = useState();

  const [item1, setItem1] = useState([]);
  const [item2, setItem2] = useState([]);
  const [item3, setItem3] = useState([]);
  const [item4, setItem4] = useState([]);

  const lineData = {
    labels,
    datasets: [
      {
        label: label4,
        data: item4,
        borderColor: "#0d6efd",
        backgroundColor: "#0d6efd66",
      },
      {
        label: label3,
        data: item3,
        borderColor: "#ff004e66",
        backgroundColor: "#ff004e66",
      },
      {
        label: label2,
        data: item2,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: label1,
        data: item1,
        borderColor: "rgb(255, 205, 86)",
        backgroundColor: "rgba(255, 205, 86, 0.5)",
      },
    ],
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async (year, month, day) => {
    httpClient()
      .get(
        `/admin/analysis/components/range-aggregation/${
          year
            ? year
            : new Date().getFullYear() < 10
            ? `0${new Date().getFullYear()}`
            : new Date().getFullYear()
        }/${
          month
            ? month
            : new Date().getMonth() < 10
            ? `0${new Date().getMonth()}`
            : new Date().getMonth()
        }/${
          day
            ? day
            : new Date().getDate() < 10
            ? `0${new Date().getDate()}`
            : new Date().getDate()
        }`
      )
      .then((res) => {
        console.log(res.data);
        res.data.map((item, i) => {
          if (i === 0) {
            setLabel1(item.date);
            setItem1(item?.data?.map((item) => item?.total));
          } else if (i === 1) {
            setLabel2(item.date);
            setItem2(item?.data?.map((item) => item?.total));
          } else if (i === 2) {
            setLabel3(item.date);
            setItem3(item?.data?.map((item) => item?.total));
          } else if (i === 3) {
            setLabel4(item.date);
            setItem4(item?.data?.map((item) => item?.total));
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPanelContainer title="Component Analysis( Yearly )" sx={{ p: 1 }}>
      <Box
        sx={{
          backgroundColor: theme.ui,
          color: theme.textColor,
          padding: 2,
          borderRadius: 2,
          gap: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          for="monthYear"
          variant="h6"
          sx={{ color: theme.textColor3, fontWeight: 500 }}
        >
          Select a (month and year):
        </Typography>
        <input
          type="date"
          style={{
            border: `1px solid ${theme.borderColor}`,
            borderRadius: 2,
            color: theme.textColor2,

            backgroundColor: theme.backgroundColor,

            height: 56,
            paddingInline: 16,
            borderRadius: 8,
          }}
          value={monthSelect}
          onChange={(e) => {
            const [year, month, day] = e.target.value.split("-");
            console.log({ year, month, day });
            setMonthSelect(e.target.value);
            getData(year, month, day);
          }}
        />
        {/* <TextField
          variant="outlined"
          type="date"
          id="monthYear"
          name="monthYear"
          value={monthSelect}
          sx={{
            border: `1px solid ${theme.borderColor}`,
            borderRadius: 2,
            color: theme.textColor2,
          }}
          onChange={(e) => {
            const [year, month, day] = e.target.value.split("-");
            console.log({ year, month, day });
            setMonthSelect(e.target.value);
            getData(year, month, day);
          }}
        /> */}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: 2,
        }}
      >
        {/* <InfoCard
          title="Real Time Components"
          subTitle="Real time components created last hour"
          value={0}
        /> */}

        <InfoCard
          title={`Components of ${label1}`}
          value={item1.reduce((acc, item) => acc + item, 0).toLocaleString()}
        />

        <InfoCard
          title={`Components of ${label2}`}
          value={item2.reduce((acc, item) => acc + item, 0).toLocaleString()}
        />

        <InfoCard
          title={`Components of ${label3}`}
          value={item3.reduce((acc, item) => acc + item, 0).toLocaleString()}
        />

        <InfoCard
          title={`Components of ${label4}`}
          value={item4.reduce((acc, item) => acc + item, 0).toLocaleString()}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: bp?.md ? "1fr" : "1fr 1fr",
          gap: 2,
        }}
      >
        <Box>
          <Line
            color="red"
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={lineData}
          />
        </Box>

        <Box>
          <Bar
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={lineData}
          />
        </Box>
      </Box>
    </CPanelContainer>
  );
}
