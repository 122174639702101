import { Box, Tab, Tabs, Skeleton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CPanelContainer from "src/components/CPanelContainer";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";
import BentoProjectItems from "./_components/BentoProjectItems";
import { TabContext } from "@mui/lab";
import { stringify } from "querystring";
import { useInView } from "react-intersection-observer";

export default function BentoFeatured() {
  const { ref, inView, entry } = useInView({ threshold: 0 });

  const { theme } = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [projects, setProjects] = useState([]);

  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("");
  useEffect(() => {
    getData();
  }, [tab]);

  const getData = (curPage = 1) => {
    const perPage = 20;
    setIsLoading(true);

    const query = {
      page: curPage,
      perPage,
      filter: JSON.stringify({
        isFeatured: tab === "featured" ? true : false,
      }),
    };

    httpClient(process.env.REACT_APP_COPILOT_URL)
      .get(`/projects?${stringify(query)}`)
      .then((res) => {
        setProjects((prev) => {
          return curPage === 1 ? res.data.data : [...prev, ...res.data.data];
        });
        setData(res.data);

        setIsLoading(false);
      })
      .catch((err) => {
        setData();
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchNextPage = () => {
    if (data?.hasMore) getData(data?.page + 1);
  };

  useEffect(() => {
    console.log("inView : ");
    fetchNextPage();
  }, [inView]);

  const handleDelete = (id) => {
    setIsLoading(true);
    httpClient()
      .delete(`/admin/bento/projects/${id}`)
      .then((res) => {
        console.log(res);
        getData();
        setIsLoading(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  return (
    <CPanelContainer title="Bento Featured" sx={{ p: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <PDInput
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />
        <PDButton sx={{ maxWidth: 150 }}>Search</PDButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TabContext value={tab}>
          <Box>
            <Tabs
              value={tab}
              onChange={(e, n) => {
                setTab(n);
              }}
              aria-label="basic tabs example"
              sx={{
                backgroundColor: theme.ui,
                borderRadius: 1,
                border: `1px ${theme.borderColor} solid`,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                mb: 2,
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="All" value="" />
              <Tab label="Featured" value="featured" />
            </Tabs>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                gap: 4,
              }}
            >
              {projects?.map((item) => (
                <BentoProjectItems
                  item={item}
                  getData={getData}
                  handleDelete={handleDelete}
                />
              ))}

              {data?.hasMore &&
                isLoading &&
                Array.from({ length: 20 }).map((_, i) => (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "100%",
                      height: 200,
                    }}
                    key={i}
                  />
                ))}
            </Box>
          </Box>

          <Box sx={{ my: 5 }}>
            {data?.hasMore && (
              <PDButton ref={ref} onClick={() => fetchNextPage()}>
                Load More
              </PDButton>
            )}
          </Box>
        </TabContext>
      </Box>
    </CPanelContainer>
  );
}
