import {
  Box,
  Divider,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "src/context";
import ProfileMenu from "./_components/ProfileMenu";
// import NotificationMenu from "./_components/NotificationMenu";
import { Link } from "react-router-dom";
// import logo from "src/assets/logo.svg";
import LightModeIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeIcon from "@mui/icons-material/DarkModeOutlined";

export default function Header({ title, subTitle }) {
  const { theme, bp, toggleDarkMode, enableDarkMode } =
    useContext(ThemeContext);

  return (
    <Box
      sx={{
        minHeight: subTitle ? 75 : 60,
        // minHeight: 100,
        py: 1,
        width: "100%",
        backgroundColor: theme.ui || theme.backgroundColor,
        borderBottom: `1px ${theme.borderColor} solid`,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          px: 2,
          width: "calc(100% - 16px)",
          maxWidth: "max(calc(100vw - 307px), 1500px)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {bp?.md && (
            <Link
              to="/"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: 25 }}
                src="https://app.jutsu.ai/favicon.ico"
                alt="Jutsu Logo"
              />
            </Link>
          )}

          <Box>
            <Typography
              variant="h4"
              sx={{ color: theme.textColor, fontWeight: 600 }}
            >
              {title}
            </Typography>

            {subTitle && (
              <Typography
                variant="p"
                sx={{ color: theme.textColor2, fontWeight: 400 }}
              >
                {subTitle}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Tooltip
            title={
              enableDarkMode ? "Currently dark mode" : "Currently light mode"
            }
            arrow
          >
            <IconButton onClick={() => toggleDarkMode()}>
              {enableDarkMode ? (
                <DarkModeIcon sx={{ color: theme.textColor2 }} />
              ) : (
                <LightModeIcon sx={{ color: theme.textColor2 }} />
              )}
            </IconButton>
          </Tooltip>
          <ProfileMenu />
        </Box>
      </Box>
    </Box>
  );
}
