import React, { useContext } from "react";
import { ThemeContext } from "src/context";
import { Box, Typography } from "@mui/material";

export default function InfoCard({ title, subTitle, value }) {
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        backgroundColor: theme.ui,
        color: theme.textColor,
        padding: 2,
        borderRadius: 2,
        gap: 1,

        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: theme.textColor3, fontWeight: 500 }}
      >
        {title}
      </Typography>

      <Typography variant="h2" sx={{ color: theme.textColor }}>
        {value}
      </Typography>

      {subTitle && (
        <Typography
          variant="p2"
          sx={{ color: theme.textColor3, fontWeight: 500 }}
        >
          {subTitle}
        </Typography>
      )}
    </Box>
  );
}
