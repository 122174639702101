import { useContext, useEffect, useState } from "react";
import CPanelContainer from "src/components/CPanelContainer";

import { Bar, Line } from "react-chartjs-2";
import httpClient from "src/libs/httpClient";
import accumulateTotals from "src/libs/accumulateTotals";
import { ThemeContext } from "src/context";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InfoCard from "src/components/InfoCard";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Jutsu's Monthly Component Analysis",
    },
  },
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function YearlyComponentAnalysesPage() {
  const { theme, bp } = useContext(ThemeContext);

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const lineData = {
    labels,
    datasets: [
      {
        label: "Monthly Component Analysis",
        data: accumulateTotals(data),
        borderColor: "#0d6efd",
        backgroundColor: "#0d6efd66",
      },
    ],
  };

  const barData = {
    labels,
    datasets: [
      {
        label: "Monthly Component Analysis",
        data: data.map((item) => item.total),
        borderColor: "#ff004e",
        backgroundColor: "#ff004e66",
      },
    ],
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    httpClient()
      .get("/admin/analysis/components/range-aggregation")
      .then((res) => {
        console.log(res.data);

        setData(res.data);

        const labels = res.data.map((item) => {
          return `${monthNames[item?._id?.month - 1]}, ${item?._id?.year}`;
        });

        setLabels(labels);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPanelContainer
      title="Yearly Component Analysis"
      subTitle="This page shows the month over month growth"
      sx={{ p: 1 }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: 2,
        }}
      >
        <InfoCard
          title="Total Components"
          value={(
            data.reduce((acc, item) => acc + item.total, 0) || 0
          ).toLocaleString()}
        />

        <InfoCard
          title={`Highest Grossing Month (${
            monthNames[
              data?.reduce(
                (max, current) => (current.total > max.total ? current : max),
                data[0]
              )?._id?.month - 1
            ]
          })`}
          value={(
            data.reduce((acc, item) => Math.max(acc, item.total), 0) || 0
          ).toLocaleString()}
        />

        <InfoCard
          title="Lowest Grossing Month"
          value={(
            data.reduce((acc, item) => Math.min(acc, item.total), Infinity) || 0
          ).toLocaleString()}
        />

        <InfoCard
          title="This Month's Total"
          value={(
            data[data.length - 1]?.total?.toLocaleString() || 0
          ).toLocaleString()}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: bp?.md ? "1fr" : "1fr 1fr",
          gap: 2,
        }}
      >
        <Box>
          <Line
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={lineData}
          />
        </Box>

        <Box>
          <Bar
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={barData}
          />
        </Box>
      </Box>

      <Box>
        <TableContainer
          sx={{
            backgroundColor: theme.ui,
            color: theme.textColor,
            borderRadius: 2,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: theme.textColor }}>Month</TableCell>
                <TableCell sx={{ color: theme.textColor }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...data].reverse()?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: theme.textColor + "11",
                    },
                  }}
                >
                  <TableCell sx={{ border: "none", color: theme.textColor2 }}>
                    {monthNames[item?._id?.month - 1]}, {item?._id?.year}
                  </TableCell>
                  <TableCell sx={{ border: "none", color: theme.textColor2 }}>
                    {item.total.toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CPanelContainer>
  );
}
