import { useContext, useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";
import { Box, Typography } from "@mui/material";
// import AddBrandDialog from "src/dialogs/AddBrandDialog";

import CPanelContainer from "src/components/CPanelContainer";
import { ThemeContext } from "src/context";

import FeedbackSidebar from "./_components/FeedbackSidebar";
import { useParams } from "react-router-dom";
import FeedbackFullview from "./_components/FeedbackFullview";

export default function FeedbackPage() {
  const { feedbackId } = useParams();
  const { theme, bp } = useContext(ThemeContext);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [onFocus, setOnFocus] = useState("");

  console.log(feedbackId);
  useEffect(() => {
    if (!feedbackId || feedbackId === undefined) {
      setLoading(true);
      getFeedbacks();
      let feedbackInterval = setInterval(() => {
        getFeedbacks();
      }, 10000);
      return () => clearInterval(feedbackInterval);
    }
  }, [feedbackId]);

  const getFeedbacks = () => {
    httpClient()
      .get(`/admin/feedback`)
      .then((res) => {
        console.log(res.data);
        setFeedbacks(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  // console.log(isSendEmail);
  return (
    <>
      <CPanelContainer
        title={`Feedback`}
        sx={{ p: 1, maxHeight: "calc(100dvh)" }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: bp?.md ? "1fr" : "1fr 3fr",
            // gap: 2,
            borderRadius: 1,
            // overflow: "hidden",
            minHeight: "calc(100dvh - 100px)",
            border: `1px ${theme.borderColor} solid`,
          }}
        >
          <FeedbackSidebar
            setIsOpened={setIsOpened}
            feedbacks={feedbacks}
            loading={loading}
            theme={theme}
            onFocus={onFocus}
            setOnFocus={setOnFocus}
          />
          <Box
            id="feedback-fullview"
            sx={{
              // position: "relative",
              backgroundColor: theme.ui,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {!feedbackId || feedbackId === undefined ? (
              <Typography variant="h2" sx={{ color: theme.textColor + 10 }}>
                Empty feedback
              </Typography>
            ) : (
              <FeedbackFullview
                isOpened={isOpened}
                getFeedbacks={getFeedbacks}
                theme={theme}
                feedbackId={feedbackId}
              />
            )}
          </Box>
        </Box>
      </CPanelContainer>
    </>
  );
}
