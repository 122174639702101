import {
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  ButtonBase,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext, ThemeContext } from "src/context";
import capitalizeWords from "src/libs/capitalizeWords";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useState } from "react";

import httpClient from "src/libs/httpClient";
import PDSelect from "src/components/_pdStyle/PDSelect";
import { toast } from "sonner";
import ActionDialog from "src/dialogs/ActionDialog";
import PDButton from "src/components/_pdStyle/PDButton";
import EditIcon from "@mui/icons-material/Edit";

const styles = {
  tableCell: {
    p: 0,
    py: 1,

    pl: 1,
  },
};

const dateFormater = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export default function UsersTable({ users, getData, loading, perPage }) {
  const { theme } = useContext(ThemeContext);

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", border: `1px ${theme.borderColor} solid` }}
    >
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <div
            style={{
              width: "100%",
              height: 40.5,
              borderRadius: ".25rem",
              backgroundColor: theme.ui,
            }}
          />
          {Array.from(new Array(perPage)).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 56,
                borderRadius: ".25rem",
                backgroundColor: theme.ui,
              }}
            />
          ))}
        </div>
      ) : (
        <Fade in={!loading}>
          <div>
            {users?.length > 0 ? (
              <Table
                sx={{ minWidth: 650, backgroundColor: theme.ui }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& th": {
                        color: theme.textColor,
                      },
                    }}
                  >
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="lect">Email</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Privider</TableCell>

                    <TableCell align="center">Credits</TableCell>

                    <TableCell align="center">Is Active</TableCell>
                    <TableCell align="center">Created At</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {users.map((row, index) => {
                    return row?.empty ? (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: theme.ui,
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:nth-child(odd)": {
                            backgroundColor: theme.ui2 + "33",
                          },

                          "& td": {
                            borderBottom: `1px ${theme.borderColor} solid`,
                            height: 56,
                          },
                        }}
                      >
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                      </TableRow>
                    ) : (
                      <TableItem key={row?._id} row={row} getData={getData} />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "50px 20px",
                  border: `1px solid ${theme.borderColor}`,
                }}
              >
                <p style={{ textAlign: "center" }}>Users list is empty.</p>
              </div>
            )}
          </div>
        </Fade>
      )}
    </TableContainer>
  );
}

const TableItem = ({ row, getData }) => {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const [showSetupStoreDialog, setShowSetupStoreDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [credits, setCredits] = useState(row?.credits);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showCreditsDialog, setShowCreditsDialog] = useState(false);
  const [isActive, setIsActive] = useState(row?.isActive);
  const [role, setRole] = useState({
    value: row?.role,
    label: capitalizeWords(row.role),
  });

  const updateState = (e) => {
    if (e.hasOwnProperty("isActive")) {
      setIsActive(e.isActive);
    }
    if (e.hasOwnProperty("role")) {
      setRole({
        value: e.role,
        label: capitalizeWords(e.role),
      });
    }

    // setLoading(true);
    httpClient()
      .put(`admin/users/${row._id}`, e)
      .then((res) => {
        toast(`User status update successfully`, {
          variant: "success",
        });
        // setLoading(false);
      })
      .catch((err) => {
        if (e.hasOwnProperty("isActive")) {
          setIsActive(row?.isActive);
        }
        if (e.hasOwnProperty("role")) {
          setRole({
            value: row?.role,
            label: capitalizeWords(row?.role),
          });
        }

        console.log(err);
        toast(`Faild to update user`, {
          variant: "error",
        });
        // setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    httpClient()
      .delete(`admin/users/${row._id}`)
      .then((res) => {
        toast(`User deleted successfully`, {
          variant: "success",
        });
        getData();
        setLoading(false);
      })
      .catch((err) => {
        toast(`Faild to delete user`, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const handleCredits = async () => {
    setLoading(true);
    await httpClient()
      .put(`admin/users/${row._id}`, { credits: credits })
      .then((res) => {
        toast(`Credit updated successfully`, {
          variant: "success",
        });
        getData();
        setLoading(false);
      })
      .catch((err) => {
        toast(`Faild to update credit`, {
          variant: "error",
        });
        setLoading(false);
      });
    setShowCreditsDialog(false);
  };

  const onCopyButtonClick = async () => {
    try {
      // await navigator.clipboard.writeText(`<Widget src="${textToCopy}" />`);
      await navigator.clipboard.writeText(row?.userName || row?.email);

      toast("Username copied to clipboard.", { type: "success" });
    } catch (error) {
      toast("Failed to copy username.", { type: "error" });
    }
  };

  return loading ? (
    <TableRow
      sx={{
        backgroundColor: theme.ui,
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": {
          backgroundColor: theme.ui2 + "33",
        },

        "& td": {
          height: 57,
          borderBottom: `1px ${theme.borderColor} solid`,
        },
      }}
    >
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
    </TableRow>
  ) : (
    <>
      <TableRow
        onMouseEnter={() => setShowEditIcon(true)}
        onMouseLeave={() => setShowEditIcon(false)}
        sx={{
          // backgroundColor: theme.backgroundColor,
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-child(odd)": { backgroundColor: theme.ui2 + "33" },
          "& td": {
            borderBottom: `1px ${theme.borderColor} solid`,
          },
        }}
      >
        <TableCell sx={styles.tableCell}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar src={row?.avatar}>{row?.userName?.substring(0, 1)}</Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ color: theme.textColor, fontWeight: 500 }}
                variant="h6"
              >
                {row?.name}
              </Typography>
              <ButtonBase text={row?.userName} onClick={onCopyButtonClick}>
                <Tooltip title={copied ? "Copied" : "Copy to clipboard"} arrow>
                  <Typography
                    sx={{ color: theme.textColor2, cursor: "pointer" }}
                    variant="p"
                  >
                    @{row?.userName.substring(0, 25)}
                    {row?.userName.length > 25 && "..."}
                  </Typography>
                </Tooltip>
              </ButtonBase>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left">
          <Typography sx={{ color: theme.textColor2 }} variant="p">
            {row?.email ? row.email : "N/A"}
          </Typography>
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          {["user", "admin"].includes(row?.role) ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                color: theme.textColor2,
              }}
            >
              <PDSelect
                sx={{
                  maxWidth: 200,
                  color: theme.textColor,
                }}
                required
                defaultValue={{
                  value: row?.role,
                  label: capitalizeWords(row.role),
                }}
                options={rolesOptions.map((o) => {
                  return { value: o, label: capitalizeWords(o) };
                })}
                value={role}
                onChange={(e) => {
                  updateState({ role: e.value });
                }}
              />
            </Box>
          ) : (
            capitalizeWords(row.role)
          )}
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          <Chip
            sx={{ color: theme.textColor, backgroundColor: theme.ui2 }}
            label={row?.provider}
          />
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          <Typography
            onClick={() => setShowCreditsDialog(true)}
            sx={{
              color: theme.textColor2,
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
            variant="p"
          >
            {row?.credits}

            <Fade in={showEditIcon}>
              <EditIcon
                sx={{ display: showEditIcon ? "block" : "none" }}
                fontSize="10px"
              />
            </Fade>
          </Typography>
        </TableCell>

        <TableCell align="center" sx={{ ...styles.tableCell }}>
          <Switch
            checked={isActive}
            onChange={(e) => updateState({ isActive: e.target.checked })}
          />
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          <Typography sx={{ color: theme.textColor2 }} variant="p">
            {dateFormater(row?.createdAt)}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={styles.tableCell}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              mr: 1,
            }}
          >
            <IconButton onClick={() => setShowConfirmButton((e) => !e)}>
              <DeleteRoundedIcon color="error" />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <CreditsDialog
        theme={theme}
        open={showCreditsDialog}
        setOpen={setShowCreditsDialog}
        credits={credits}
        setCredits={setCredits}
        handleCredits={handleCredits}
      />
      <ActionDialog
        open={showConfirmButton}
        setOpen={setShowConfirmButton}
        title="Delete User"
        description={`Are you sure you want to delete this user? This action is irreversible.`}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        secondaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          handleDelete();
          setShowConfirmButton(false);
        }}
      />
    </>
  );
};

const rolesOptions = ["super", "admin", "user"];

const CreditsDialog = ({
  open,
  setOpen,
  setCredits,
  credits,
  theme,
  handleCredits,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          backgroundImage: "none",
          "& .MuiPaper-root": {
            backgroundImage: "none",
            backgroundColor: theme.backgroundColor,
          },
        },
      }}
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        Edit credits:
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        <InputBase
          sx={{
            backgroundColor: theme.ui2,
            borderRadius: 1,
            padding: "0 10px",
            color: theme.textColor,
            "&:hover": {
              backgroundColor: theme.ui2,
            },
          }}
          placeholder="Enter credits"
          value={credits}
          type="number"
          onChange={(e) => setCredits(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <PDButton
            variant="contained"
            sx={{
              color: "#fcf5f7",
              backgroundColor: "#bc002d",
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </PDButton>
          <PDButton variant="contained" color="primary" onClick={handleCredits}>
            Submit
          </PDButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
