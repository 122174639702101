import { useContext, useState } from "react";

import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import StoreRoundedIcon from "@mui/icons-material/StoreRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FitbitRoundedIcon from "@mui/icons-material/FitbitRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";

import { AuthContext, ThemeContext } from "src/context";
import PDMenu from "src/components/_pdStyle/PDMenu";
import { useNavigate } from "react-router-dom";
import { adminMenuPrimary } from "src/data/AdminMenuData";

export default function CPanelBottomBar() {
  const { theme } = useContext(ThemeContext);
  const { logout, role } = useContext(AuthContext);

  const navigate = useNavigate();

  const [curPage, setCurPage] = useState("");

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.ui,
        backgroundImage: "none",
        zIndex: 100,
      }}
      elevation={3}
    >
      <BottomNavigation
        // showLabels
        sx={{ px: "16px", backgroundColor: theme.ui }}
        value={curPage}
        onChange={(event, newValue) => {
          setCurPage(newValue);

          if (newValue !== "more") {
            navigate(newValue);
          }
        }}
      >
        {adminMenuPrimary.map((item, index) => {
          return (
            !item?.children && (
              <BottomNavigationAction
                sx={{ p: 0, minWidth: 30 }}
                label={""}
                value={item?.to}
                icon={item?.icon}
              />
            )
          );
        })}

        <PDMenu
          icon={<MoreVertIcon sx={{ fill: "currentcolor" }} />}
          hideArrow={true}
          menuStyle={{
            width: 175,
            mt: -5.5,
            "&:before": {
              display: "none",
            },
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: -10,
              // right: 28,
              right: 26,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          }}
          items={[
            {
              label: "Logout",
              onClick: () => logout(),
              startIcon: <LogoutRoundedIcon fontSize="small" />,
            },
            {
              label: "Settings",
              onClick: () => navigate("/settings"),
              startIcon: <SettingsOutlinedIcon fontSize="small" />,
            },
            ["admin", "super"].includes(role) && { divider: true },

            ["admin", "super"].includes(role) && {
              label: "Categories",
              onClick: () => navigate("/categories"),
              startIcon: <CategoryRoundedIcon fontSize="small" />,
            },
            ["admin", "super"].includes(role) && {
              label: "Brands",
              onClick: () => navigate("/brands"),
              startIcon: <FitbitRoundedIcon fontSize="small" />,
            },
          ]}
        />
      </BottomNavigation>
    </Paper>
  );
}
