import PDButton from "src/components/_pdStyle/PDButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useContext, useEffect, useState } from "react";
import ProductsTable from "./_components/ProductsTable";
import httpClient from "src/libs/httpClient";
import { stringify } from "querystring";
import PDPagiation from "src/components/_pdStyle/PDPagiation";

import { TabContext } from "@mui/lab";
// import { useRouter } from "next/router";
import { useSearchParams } from "react-router-dom";

import { Box, Tab, Tabs } from "@mui/material";
import { AuthContext, ThemeContext } from "src/context";
import { useDebounce } from "use-debounce";
import PDInput from "src/components/_pdStyle/PDInput";
import CPanelContainer from "src/components/CPanelContainer";

export default function ProductsPage() {
  // const { query } = useRouter();
  let [searchParams, setSearchParams] = useSearchParams();

  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);

  const [tab, setTab] = useState(searchParams?.tab || "");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [search, setSearch] = useState("");
  const [searchDebounce] = useDebounce(search, 500);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [page, perPage, tab, searchDebounce]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    const query = {
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        status:
          tab !== undefined && tab !== "isArchived" && tab !== "isDeleted"
            ? tab
            : "",
        search: searchDebounce !== undefined ? searchDebounce : "",

        isArchived: tab === "isArchived" ? true : false,
        isDeleted: tab === "isDeleted" ? true : false,
      }),
    };

    httpClient()
      .get(`/sellers/products?${stringify(query)}`)
      .then((res) => {
        let data = res?.data?.result || [];

        if (res?.data?.result.length > 0) {
          while (res?.data?.result.length < perPage) {
            data.push({ empty: true });
          }
        }

        setProducts(data);
        setPageCount(Math.ceil(res.data?.count / perPage));
        setTotalCount(res.data?.count);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <CPanelContainer title="Products">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <PDInput
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ backgroundColor: theme.ui }}
          />
          <PDButton
            sx={{ maxWidth: 150 }}
            startIcon={<AddRoundedIcon />}
            onClick={() => setShowAddProductDialog((e) => !e)}
          >
            Add Product
          </PDButton>
        </Box>

        <TabContext value={tab}>
          <Box>
            <Tabs
              value={tab}
              onChange={(e, n) => {
                setTab(n);
                setPage(1);
              }}
              aria-label="basic tabs example"
              sx={{
                backgroundColor: theme.ui,
                borderRadius: 1,
                border: `1px ${theme.borderColor} solid`,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="All" value="" />
              <Tab label="Active" value="active" />
              <Tab label="Pending" value="pending" />
              <Tab label="Reviewing" value="reviewing" />
              <Tab label="On Hold" value="onHold" />
              <Tab label="Inactive" value="inactive" />

              <Tab label="Archived" value="isArchived" />
              {["admin", "super"].includes(user?.role) && (
                <Tab label="Deleted" value="isDeleted" />
              )}
            </Tabs>

            <ProductsTable
              products={products}
              loading={loading}
              perPage={perPage}
              getData={getData}
            />

            <PDPagiation
              pageCount={pageCount}
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Box>
        </TabContext>
      </CPanelContainer>

      {/* <AddProductDialog
        open={showAddProductDialog}
        setOpen={setShowAddProductDialog}
        getData={getData}
      /> */}
    </>
  );
}
