import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import httpClient from "src/libs/httpClient";

export default function FeedbackSidebar({
  theme,
  feedbacks,
  loading,
  setIsOpened,
  onFocus,
  setOnFocus,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: 1,
        backgroundColor: theme.ui,
        overflowY: "scroll",
        maxHeight: "calc(100dvh - 100px)",
        minWidth: "max-content",
        // p: 2,
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        feedbacks?.map((item, index) => {
          // console.log("Created", item?.createdAt);
          return (
            item?.email && (
              <ButtonBase
                key={index}
                onClick={() => {
                  setIsOpened(true);
                  setOnFocus(item?._id);
                }}
                component={Link}
                to={`/feedback/${item?._id}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "relative",
                  gap: 1,
                  backgroundColor:
                    item?.hasUnseenMessageFromCustomer === true ||
                    onFocus === item?._id
                      ? theme.ui2
                      : theme.ui2 + 40,
                  // background:
                  //   feedbacks[index]?.email === feedbackMessages?.email &&
                  //   theme.ui2,
                  p: 2,
                  borderBottom: `1px ${theme.borderColor} solid`,

                  "&:hover": {
                    backgroundColor: theme.ui2 + 80,
                  },
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    display: onFocus === item?._id ? "block" : "none",
                    position: "absolute",
                    left: 0,
                    height: "100%",
                    borderLeft: `4px ${theme.textColor2} solid`,
                  }}
                />
                {item?.hasUnseenMessageFromCustomer === true && (
                  <span
                    style={{
                      backgroundColor: theme.buttonColor + 80,
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  />
                )}
                <img
                  src={
                    item?.createdBy?.avatar ||
                    `https://source.boringavatars.com/pixel/40/${
                      item?.createdBy?.email ||
                      item?.email ||
                      item?.createdBy?.userName
                    }?colors=0A0310,49007E,FF005B,FF7D10,FFB238`
                  }
                  alt="not found"
                  style={{ width: 40, height: 40, borderRadius: 50 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6" sx={{ color: theme.textColor2 }}>
                      {item?.createdBy?.email ||
                        item?.email ||
                        item?.createdBy?.userName ||
                        "NaN"}
                    </Typography>
                    <Typography variant="p2" sx={{ color: theme.textColor3 }}>
                      {moment(
                        item?.replies?.[item?.replies?.length - 1]?.timestamp ||
                          item?.createdAt
                      ).fromNow()}
                    </Typography>
                  </Box>
                  <Typography
                    variant="p2"
                    sx={{
                      color: theme.textColor2,
                      width: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.initialMessage?.text}
                  </Typography>
                </Box>
              </ButtonBase>
            )
          );
        })
      )}
    </Box>
  );
}
