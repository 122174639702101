import {
  Avatar,
  Box,
  Chip,
  Fade,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext, ThemeContext } from "src/context";
import capitalizeWords from "src/libs/capitalizeWords";
import statusToColor from "src/libs/statusToColor";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import UnarchiveRoundedIcon from "@mui/icons-material/UnarchiveRounded";
import PDMenu from "src/components/_pdStyle/PDMenu";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";
import PDSelect from "src/components/_pdStyle/PDSelect";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import InventoryIcon from "@mui/icons-material/Inventory";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";

const styles = {
  tableCell: { p: 0, py: 1, pl: 1 },
};

export default function ProductsTable({ products, getData, loading, perPage }) {
  const { theme } = useContext(ThemeContext);

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: `1px ${theme.borderColor} solid`,
        borderTop: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        mb: 2,
      }}
    >
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <div
            style={{
              width: "100%",
              height: 40.5,
              borderRadius: ".25rem",
              backgroundColor: "#f2f2f2",
            }}
          />
          {Array.from(new Array(perPage)).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 57,
                borderRadius: ".25rem",
                backgroundColor: "#f2f2f2",
              }}
            />
          ))}
        </div>
      ) : (
        <Fade in={!loading}>
          <div>
            {products?.length > 0 ? (
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}>Name</TableCell>
                    <TableCell align="center" sx={styles.tableCell}>
                      Price (৳)
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Inventory
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      View
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Status
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      Out Of Stock
                    </TableCell>

                    <TableCell align="right" sx={styles.tableCell} />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {products.map((row, index) => {
                    return row?.empty ? (
                      <TableRow
                        sx={{
                          height: 57,
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:nth-child(odd)": {
                            backgroundColor: theme.backgroundColor,
                          },
                        }}
                      >
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                      </TableRow>
                    ) : (
                      <TableItem key={index} row={row} getData={getData} />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  py: 10,
                  px: 2,
                }}
              >
                <Typography align="center" sx={{ color: theme.textColor3 }}>
                  You don&apos;t have any products.
                </Typography>
              </Box>
            )}
          </div>
        </Fade>
      )}
    </TableContainer>
  );
}

const TableItem = ({ row, getData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(AuthContext);

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({
    value: row?.status,
    label: capitalizeWords(row?.status),
  });
  const [isArchived, setIsArchived] = useState(row?.isArchived);
  const [isDeleted, setIsDeleted] = useState(row?.isDeleted);
  const [isOutOfStock, setIsOutOfStock] = useState(row?.isOutOfStock);
  const [price, setPrice] = useState(row?.price);

  const [discount, setDiscount] = useState();
  const [discountRate, setDiscountRate] = useState(0);

  const handleChangeStatus = (e) => {
    setLoading(true);
    console.log(e);

    if (e?.hasOwnProperty("isArchived")) {
      setIsArchived(e?.isArchived);
    }
    if (e?.hasOwnProperty("isDeleted")) {
      setIsDeleted(e?.isDeleted);
    }
    if (e?.hasOwnProperty("isOutOfStock")) {
      setIsOutOfStock(e?.isOutOfStock);
    }
    if (e?.hasOwnProperty("status")) {
      setStatus({
        value: e?.status,
        label: capitalizeWords(e?.status),
      });
    }
    if (e?.hasOwnProperty("price")) {
      setPrice(e?.price);
    }

    httpClient()
      .put(`/sellers/products/${row?._id}`, e)
      .then((res) => {
        console.log(res.data);
        if (e?.hasOwnProperty("price")) {
          enqueueSnackbar(
            `Price updated successfully. New price is ${e.price?.toLocaleString()} BDT`,
            { variant: "success" }
          );
        } else {
          enqueueSnackbar(`Products updated successfully`, {
            variant: "success",
          });
        }

        setDiscount(false);
        setDiscountRate(0);
        getData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message) ||
            `Faild to update product`,
          { variant: "error" }
        );

        if (e?.hasOwnProperty("isArchived")) {
          setIsArchived(row?.isArchived);
        }
        if (e?.hasOwnProperty("isDeleted")) {
          setIsDeleted(row?.isDeleted);
        }
        if (e?.hasOwnProperty("isOutOfStock")) {
          setIsOutOfStock(row?.isOutOfStock);
        }
        if (e?.hasOwnProperty("status")) {
          setStatus({
            value: row?.status,
            label: capitalizeWords(row?.status),
          });
        }
        if (e?.hasOwnProperty("price")) {
          setPrice(row?.price);
        }

        getData();
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);

    httpClient()
      .delete(`/sellers/products/${row?._id}`)
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        getData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleShareClick = () => {
    const url = `https://www.facebook.com/dialog/feed?app_id=${
      process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID
    }&display=popup&link=${encodeURIComponent(
      `https://www.dokan.gg/${row?.store?.slug}/${row?.slug}`
    )}&quote=${encodeURIComponent(row.name)}&picture=${encodeURIComponent(
      process.env.REACT_APP_S3_URL + row.images[0]?.large
    )}&description=${encodeURIComponent(
      row?.description
    )}&caption=${encodeURIComponent(`Price: ${row.price}`)}`;
    window.open(url, "_blank");

    // const url = `https://www.facebook.com/dialog/feed?app_id=YOUR_APP_ID&display=popup&link=${encodeURIComponent(
    //   product.url
    // )}&name=${encodeURIComponent(product.name)}&picture=${encodeURIComponent(
    //   product.image
    // )}&description=${encodeURIComponent(
    //   product.description
    // )}&caption=${encodeURIComponent(`Price: ${product.price}`)}`;
    // window.open(url, "_blank");

    // const url = `https://www.facebook.com/dialog/feed?app_id=${
    //   process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID
    // }&display=popup&link=${encodeURIComponent(
    //   `https://www.dokan.gg/${row?.store?.slug}/${row?.slug}`
    // )}&name=${encodeURIComponent(row.name)}&picture=${encodeURIComponent(
    //   process.env.REACT_APP_S3_URL + row.images[0]?.large
    // )}&quote=${encodeURIComponent(
    //   row?.description
    // )}&caption=${encodeURIComponent(`Price: ${row.price}`)}`;
    // window.open(url, "_blank");
  };

  const [loadingSubmitForReview, setLoadingSubmitForReview] = useState(false);
  const handleSubmitForReview = () => {
    setLoadingSubmitForReview(true);

    httpClient()
      .post(`/sellers/products/${row?._id}/submitForReview`)
      .then((res) => {
        console.log(res.data);
        setLoadingSubmitForReview(false);
        enqueueSnackbar(`Review requested successfully`, {
          variant: "success",
        });

        getData();
      })
      .catch((err) => {
        console.log(err);
        setLoadingSubmitForReview(false);
        getData();
      });
  };

  return loading ? (
    <TableRow
      sx={{
        height: 57,
        backgroundColor: "#f2f2f2",
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": {
          backgroundColor: theme.backgroundColor,
        },
      }}
    >
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
    </TableRow>
  ) : (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-child(odd)": { backgroundColor: theme.backgroundColor },
        }}
      >
        <TableCell component="th" scope="row" sx={{ ...styles.tableCell }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar
              src={
                process.env.REACT_APP_S3_URL +
                (row.images[0]?.thumb || row.images[0]?.large)
              }
              alt={row.name}
              sx={{ fontWeight: 600, borderRadius: 1 }}
            >
              {row.name[0]}
            </Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                width: "100%",
              }}
            >
              <a
                href={
                  row?.status === "active"
                    ? `https://dokan.gg/products/${row?.slug}`
                    : "#"
                }
                target="_blank"
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    color: theme.textColor,
                    padding: 0,
                  }}
                >
                  {row.name}
                </Typography>
              </a>

              <Typography
                variant="p"
                sx={{ color: theme.textColor3, padding: 0 }}
              >
                Store: {row.store.name || row.store.slug}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          {/* 
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: theme.textColor,
                padding: 0,
                width: "100%",
              }}
            >
              ৳{row.price?.toLocaleString()}
            </Typography> 
          */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Tooltip
              title={
                !discount
                  ? "Enable Price Amount Mode"
                  : "Enable Discount Rate Mode"
              }
              placement="top"
            >
              <IconButton
                onClick={() => {
                  setDiscount((e) => !e);
                }}
              >
                {discount ? (
                  <LocalOfferRoundedIcon
                    sx={{
                      color: theme.buttonColor,
                    }}
                  />
                ) : (
                  <LocalOfferOutlinedIcon
                    sx={{
                      color: theme.textColor3,
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>

            {discount ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <PDInput
                  placeholder="25"
                  value={discountRate}
                  onChange={(e) => setDiscountRate(e.target.value)}
                  sx={{ backgroundColor: theme.ui }}
                  containerSx={{
                    maxWidth: 50,
                    width: "100%",
                  }}
                  type="number"
                  max={100}
                  min={0}
                />

                <Typography variant="h5">%</Typography>
              </Box>
            ) : (
              <PDInput
                placeholder="1"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                sx={{ backgroundColor: theme.ui }}
                containerSx={{ maxWidth: 150, width: "100%" }}
              />
            )}
          </Box>
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {row?.stock?.toLocaleString()}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {(row?.viewCount || 0).toLocaleString()}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {["super", "admin"].includes(user?.role) ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <PDSelect
                sx={{ maxWidth: 200 }}
                required
                defaultValue={{
                  value: row.status,
                  label: capitalizeWords(row.status),
                }}
                options={statusOptions.map((o) => {
                  return { value: o, label: capitalizeWords(o) };
                })}
                value={status}
                onChange={(e) => {
                  setStatus(e);
                  handleChangeStatus({ status: e.value });
                }}
              />
            </Box>
          ) : !row?.readyForReview && row?.status !== "active" ? (
            <PDButton
              disabled={row?.readyForReview || loadingSubmitForReview}
              loading={loadingSubmitForReview}
              sx={{ width: 150 }}
              onClick={() => handleSubmitForReview()}
            >
              Submit for review
            </PDButton>
          ) : (
            <Chip
              label={capitalizeWords(row.status)}
              sx={{
                backgroundColor: statusToColor(row.status) + 11,
                color: statusToColor(row.status),
              }}
            />
          )}
        </TableCell>

        <TableCell align="center" sx={{ p: 0 }}>
          <IconButton
            sx={{
              color: isOutOfStock ? theme.accent.red : theme.textColor3,
            }}
            onClick={() => {
              handleChangeStatus({ isOutOfStock: !isOutOfStock });
            }}
          >
            <InventoryIcon />
          </IconButton>
        </TableCell>

        <TableCell align="right" sx={styles.tableCell}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
              mr: 1,
            }}
          >
            {price !== row?.price || (discount && discountRate > 0) ? (
              <IconButton
                sx={{ color: theme.buttonColor }}
                onClick={() => {
                  if (!discount) {
                    handleChangeStatus({ price: price });
                  } else {
                    if (discountRate > 0) {
                      handleChangeStatus({
                        price: Math.round((price * (100 - discountRate)) / 100),
                      });
                    } else {
                      handleChangeStatus({ price: price });
                    }
                  }
                }}
              >
                <SaveRoundedIcon />
              </IconButton>
            ) : (
              <PDMenu
                menuStyle={{ width: 150 }}
                items={[
                  {
                    label: "Edit",
                    onClick: () => {
                      setShowAddProductDialog((e) => !e);
                    },
                    startIcon: <EditRoundedIcon fontSize="small" />,
                  },
                  ["super", "admin"].includes(user?.role) && {
                    label: "Delete",
                    onClick: () => {
                      if (["super", "admin"].includes(user?.role)) {
                        handleDelete();
                      }
                    },
                    startIcon: (
                      <DeleteRoundedIcon
                        sx={{ fill: theme.accent.red }}
                        fontSize="small"
                      />
                    ),
                    sx: {
                      backgroundColor: theme.accent.red + 11,
                      color: theme.accent.red,
                    },
                  },
                  {
                    label: isDeleted ? "Undelete" : "Delete",
                    onClick: () => {
                      handleChangeStatus({
                        isDeleted: isDeleted === true ? false : true,
                      });
                    },
                    startIcon: <DeleteRoundedIcon fontSize="small" />,
                  },
                  {
                    label: isArchived === true ? "Unarchive" : "Archive",
                    onClick: () =>
                      handleChangeStatus({
                        isArchived: isArchived === true ? false : true,
                      }),
                    startIcon:
                      row?.status === "archive" ? (
                        <UnarchiveRoundedIcon fontSize="small" />
                      ) : (
                        <ArchiveRoundedIcon fontSize="small" />
                      ),
                  },
                  {
                    label: "Share on FB",
                    onClick: () => handleShareClick(),
                    startIcon:
                      row?.status === "archive" ? (
                        <UnarchiveRoundedIcon fontSize="small" />
                      ) : (
                        <ArchiveRoundedIcon fontSize="small" />
                      ),
                  },
                ]}
              />
            )}
          </Box>
        </TableCell>
      </TableRow>

      {/* <AddProductDialog
        open={showAddProductDialog}
        setOpen={setShowAddProductDialog}
        productId={row?._id}
        getData={getData}
      /> */}
    </>
  );
};

const statusOptions = [
  "pending",
  "reviewing",
  "active",
  "onHold",
  "inactive",
  "archive",
  "delete",
];
