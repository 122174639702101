import { Box } from "@mui/material";
import FeedbackMessage from "./FeedbackMessage";
import { useState } from "react";
import httpClient from "src/libs/httpClient";

export default function FeedbackConversation({
  theme,
  getSingleFeedback,
  feedbackMessages,
  setFeedbackMessages,
  feedbackChats,
}) {
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "calc(100dvh - 230px)",
        width: "100%",
        display: "flex",
        flexDirection: "column-reverse",

        gap: 1,
        p: 1,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {/* <FeedbackMessage
    theme={theme}
    textMessage={feedbackMessages?.initialMessage?.text}
    feedbackMessages={feedbackMessages}
    timeStamp={feedbackMessages?.createdAt}
    // repliesFrom={data?.from}
  /> */}
      {feedbackChats?.map((data) => {
        return (
          <FeedbackMessage
            theme={theme}
            textMessage={data?.text}
            feedbackMessages={feedbackMessages}
            timeStamp={data?.timestamp}
            repliesFrom={data?.from}
          />
        );
      })}
    </Box>
  );
}
