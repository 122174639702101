import { Box } from "@mui/material";
import CPanelContainer from "src/components/CPanelContainer";
import PDButton from "src/components/_pdStyle/PDButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PDInput from "src/components/_pdStyle/PDInput";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import TestimonalDialog from "src/dialogs/TestimonalDialog";
import TestimonialsTable from "./_components/TestimonialsTable";
import httpClient from "src/libs/httpClient";
import PDPagiation from "src/components/_pdStyle/PDPagiation";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import { toast } from "sonner";

export default function TestimonialPage() {
  const [search, setSearch] = useState("");

  const { theme } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [hasChangedPosition, setHasChangedPosition] = useState(false);

  console.log("open: ", open);

  const [testimonal, setTestimonal] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [page, perPage]);

  const getData = () => {
    httpClient()
      .get("/admin/testimonials")
      .then((res) => {
        console.log("res.data: ", res.data);

        let data = res?.data || [];

        if (res?.data.length > 0) {
          while (res?.data.length < perPage) {
            data.push({ empty: true });
          }
        }
        setTestimonal(data);
        setPageCount(Math.ceil(res.data?.count / perPage));
        setTotalCount(res.data?.count);
        console.log(isPublic);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handlePositionUpdate = () => {
    toast("Updating position...", { type: "info" });
    setLoading(true);
    let data = testimonal.map((item, index) => {
      return {
        _id: item?._id,
        position: index,
      };
    });

    httpClient()
      .post("/admin/testimonials/updatePosition", data)
      .then((res) => {
        setHasChangedPosition(false);
        setLoading(false);
        toast("Position updated successfully", { type: "success" });
      })
      .catch((err) => {
        setLoading(false);
        toast("Failed to update position", { type: "error" });
      });
  };

  return (
    <CPanelContainer
      title={`Testimonials (${testimonal?.length})`}
      sx={{ p: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <PDInput
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />
        <PDButton
          sx={{ maxWidth: 150 }}
          startIcon={<AddRoundedIcon />}
          onClick={() => setOpen((e) => !e)}
        >
          Add Testimonal
        </PDButton>
        {hasChangedPosition && (
          <PDButton
            sx={{ maxWidth: 150, backgroundColor: "red" }}
            startIcon={<TableRowsRoundedIcon />}
            onClick={handlePositionUpdate}
          >
            Update Position
          </PDButton>
        )}
      </Box>

      <TestimonialsTable
        testimonal={testimonal}
        setTestimonal={setTestimonal}
        getData={getData}
        loading={loading}
        perPage={perPage}
        setHasChangedPosition={setHasChangedPosition}
      />

      <PDPagiation
        pageCount={pageCount}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />

      <TestimonalDialog open={open} setOpen={setOpen} />
    </CPanelContainer>
  );
}
