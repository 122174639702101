import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { forwardRef, useContext } from "react";
import { ThemeContext } from "src/context";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

export default function BentoProjectDetailsDialog({ open, setOpen }) {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen((e) => !e)}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialogContent-root": {
            overflowY: "unset",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "100%",
            backgroundColor: theme.backgroundColor,
            position: "relative",
          }}
        >
          <TableContainer
            sx={{ backgroundColor: theme.ui, color: theme.textColor }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {open?.projects?.length > 0 &&
                  open?.projects?.map((item) => (
                    <TableRow>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.model}</TableCell>
                      <TableCell>
                        <a
                          href={`https://bento.jutsu.ai/preview/${item?._id}/${item?.latest}`}
                        >
                          <OpenInNewRoundedIcon />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
