import { createContext, useEffect, useState } from "react";

import httpClient from "src/libs/httpClient";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
// import addToObject from "src/libs/addToObject";
import {
  //  useLocation,
  useNavigate,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "sonner";

export const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  // const router = useRouter();
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(["_auth_accessToken"]);
  // const jwtToken = cookies._auth_accessToken;

  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState();
  const [stores, setStores] = useState();
  const [role, setRole] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    setLoadingCheck(true);

    let accessToken;
    let userId;

    accessToken = await localStorage.getItem("accessToken");
    userId = await localStorage.getItem("userId");

    if (!accessToken) {
      const cookieToken = cookies._auth_accessToken;

      if (cookieToken) {
        await localStorage.setItem("accessToken", cookieToken);
      }

      accessToken = cookies._auth_accessToken;
    }

    if (!userId) {
      const cookieUserId = cookies._auth_userId;

      if (cookieUserId) {
        await localStorage.setItem("userId", cookieUserId);
      }

      userId = cookies._auth_userId;
    }

    if (!accessToken || !userId) {
      setLoadingCheck(false);
      setIsAuthenticated(false);
      console.log("No token or userId found in localStorage.");
      return;
    }

    httpClient()
      .get(`/users/${userId}`)
      .then((res) => {
        if (!["super", "admin"].includes(res?.data?.role)) {
          toast.error(
            "You don't have access to this website, Logging you out.",
            {
              type: "error",
            }
          );

          logout();
          setIsAuthenticated(false);
          setLoadingCheck(false);
          setLoading(false);
          return;
        }

        saveUserData(res.data);
        setLoadingCheck(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log("ERROR from ToggleAuth : ", err);
        toast("Fail to login.", { type: "error" });

        logout();
        setIsAuthenticated(false);
        setLoadingCheck(false);
        setLoading(false);
      });
  };

  const saveUserData = async (user) => {
    setLoading(true);
    try {
      setUser(user);
      setStores(user?.stores);
      setRole(user.role);
      setToken(user.accessToken);
      setIsAuthenticated(true);

      // gtag("config", "TAG_ID", {
      //   user_id: user?._id,
      // });

      user.accessToken &&
        (await localStorage.setItem("accessToken", user.accessToken));
      await localStorage.setItem("userId", user._id || user.id);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const loginWithEmail = (email, password, dontRedirect) => {
    setLoading(true);
    httpClient()
      .post("/auth/login", { email, password })
      .then((res) => res.data)
      .then((user) => {
        if (user?._id) {
          if (user.role === "buyer") {
            logout();
            toast(`You don't have access to this website.`, {
              type: "error",
            });
          } else {
            saveUserData(user);
            toast(`Welcome, ${user?.firstName} ${user?.lastName || ""}`, {
              type: "success",
            });

            navigate("/");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log("err : ", err);

        if (err?.response?.data?.redirect) {
          navigate("/signup/confirm");
          toast(
            removeUndefinedFromString(err?.response?.data?.message),
            "error"
          );
        } else {
          // "You have entered an invalid email or password",
          toast(removeUndefinedFromString(err?.response?.data?.message), {
            type: "error",
          });
        }
        setLoading(false);
      });
  };

  const signUpWithEmail = async (
    email,
    password,
    firstName,
    lastName,
    role
  ) => {
    setLoading(true);
    httpClient()
      .post("/auth/signup", {
        firstName,
        lastName,
        role,
        email,
        password,
      })
      .then((res) => res.data)
      .then((user) => {
        // saveUserData(user);
        // toast(`Welcome, ${user?.firstName || user?.email}`, {
        //   type: "info",
        // });
        navigate(`/signup/confirm?email=${email}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast(removeUndefinedFromString(err?.response?.data?.message), {
          type: "error",
        });
        setLoading(false);
      });
  };

  const forgotPassword = ({ email }) => {
    console.log(email);
    setLoading(true);
    httpClient()
      .post("/auth/forgotPassword/request", { email })
      .then((res) => {
        console.log(res.data);
        toast("Email sent successfully. Please check your inbox.", {
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast(removeUndefinedFromString(err?.response?.data?.message), {
          type: "error",
        });
        setLoading(false);
      });
  };

  const newPassword = ({ token, password }) => {
    setLoading(true);
    httpClient()
      .post(`/auth/forgotPassword/${token}`, { password })
      .then((res) => {
        console.log(res.data);
        saveUserData(res.data);
        toast(`Welcome, ${user?.firstName} ${user?.lastName}`, {
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast(removeUndefinedFromString(err?.response?.data?.message), {
          type: "error",
        });
        setLoading(false);
      });
  };

  const logout = async () => {
    setLoading(true);
    try {
      console.log("LOGINNG OUT!");
      await localStorage.removeItem("accessToken");
      await localStorage.removeItem("userId");

      removeCookie("_auth_accessToken", {
        path: "/",
        domain:
          process.env.REACT_APP_NODE_ENV === "production"
            ? ".jutsu.ai"
            : "localhost",
        secure: true,
        sameSite: "none",
      });

      removeCookie("_auth_userId", {
        path: "/",
        domain:
          process.env.REACT_APP_NODE_ENV === "production"
            ? ".jutsu.ai"
            : "localhost",
        secure: true,
        sameSite: "none",
      });

      setUser();

      navigate("/login");
      // window.location.href = "https://jutsu.ai";
    } catch (e) {
      console.log("Unable to remove user from localStorage.", e);
      setLoading(false);
    } finally {
      setIsAuthenticated(false);
      setLoading(false);
    }
  };

  const value = {
    loadingCheck,
    loading,

    isAuthenticated,
    user,
    stores,
    role,
    token,

    saveUserData,

    checkAuth,
    loginWithEmail,
    signUpWithEmail,
    forgotPassword,
    newPassword,
    logout,
  };
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
