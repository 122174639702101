import { useContext, useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";
import { stringify } from "querystring";
import PDPagiation from "src/components/_pdStyle/PDPagiation";

import { TabContext } from "@mui/lab";

import { Box, Tab, Tabs } from "@mui/material";
import { ThemeContext } from "src/context";
import { useDebounce } from "use-debounce";
import PDInput from "src/components/_pdStyle/PDInput";
import CPanelContainer from "src/components/CPanelContainer";
import SubscriberTable from "./_components/SubscriberTable";

export default function NewsletterSubscriberPage() {
  const { theme } = useContext(ThemeContext);

  const [tab, setTab] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [search, setSearch] = useState("");
  const [searchDebounce] = useDebounce(search, 500);

  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [page, perPage, tab, searchDebounce]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    const query = {
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        search: searchDebounce !== undefined ? searchDebounce : "",
        unsubscribed:
          (tab === "unsubscribers" && true) || (tab === "subscribers" && false),
      }),
    };

    httpClient()
      .get(`/admin/newslatter/subscriber?${stringify(query)}`)
      .then((res) => {
        console.log(res.data);
        let data = res?.data?.result || [];

        if (res?.data?.result.length > 0) {
          while (res?.data?.result.length < perPage) {
            data.push({ empty: true });
          }
        }

        setSubscribers(data);
        setPageCount(Math.ceil(res.data?.count / perPage));
        setTotalCount(res.data?.count);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <CPanelContainer title={`Newsletter Subscribers`}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <PDInput
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ backgroundColor: theme.ui }}
          />
        </Box>

        <TabContext value={tab}>
          <Box>
            <Tabs
              value={tab}
              onChange={(e, n) => {
                setTab(n);
                setPage(1);
              }}
              aria-label="basic tabs example"
              sx={{
                backgroundColor: theme.ui,
                borderRadius: 1,
                border: `1px ${theme.borderColor} solid`,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="All" value="" />
              <Tab label="Subscribers" value="subscribers" />
              <Tab label="Unsubscribers" value="unsubscribers" />
            </Tabs>

            <SubscriberTable
              subscribers={subscribers}
              loading={loading}
              perPage={perPage}
              getData={getData}
            />

            <PDPagiation
              pageCount={pageCount}
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </Box>
        </TabContext>
      </CPanelContainer>

      {/* <AddProductDialog
        open={showAddProductDialog}
        setOpen={setShowAddProductDialog}
        getData={getData}
      /> */}
    </>
  );
}
