import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import { Box } from "@mui/material";

import LoadingPage from "./components/LoadingPage";

// Main Router

import ProductsPage from "./pages/productsPage/ProductsPage";

// Root Router
import LoginPage from "./pages/auth/LoginPage";
import ConfirmEmailPage from "./pages/auth/signup/ConfirmEmailPage";
import SellerSignupPage from "./pages/auth/signup/SellerSignupPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import SettingsPage from "./pages/settingsPage/SettingsPage";
import Custom404Page from "./pages/errorPages/Custom404Page";
import FeedbackPage from "./pages/feedbackPage/FeedbackPage";
import NewsletterSubscriberPage from "./pages/newsletter/subscriber/NewsletterSubscriberPage";

import YearlyComponentAnalysesPage from "./pages/analyses/componentAnalyses/YearlyComponentAnalysesPage";
import MonthlyComponentAnalysesPage from "./pages/analyses/componentAnalyses/MonthlyComponentAnalysesPage";
import DailyComponentAnalysesPage from "./pages/analyses/componentAnalyses/DailyComponentAnalysesPage";

import CPanelSidebar from "./components/CPanelSidebar";
import { ThemeContext } from "./context";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import YearlyUserAnalysesPage from "./pages/analyses/userAnalyses/YearlyUserAnalysesPage";
import MonthlyUserAnalysesPage from "./pages/analyses/userAnalyses/MonthlyUserAnalysesPage";
import DailyUserAnalysesPage from "./pages/analyses/userAnalyses/DailyUserAnalysesPage";
import PartnersPage from "./pages/partnersPage/PartnersPage";
import TestimonialPage from "./pages/testimonial/TestimonialPage";
import BentoFeatured from "./pages/bentoFeatured/BentoFeatured";
import UsersPage from "./pages/users/UsersPage";
import CountPerUserPage from "./pages/analyses/componentAnalyses/CountPerUserPage";
import ForkAnalysesPage from "./pages/analyses/ForkAnalysesPage";
import ComponentAnalysesPage from "./pages/analyses/componentAnalyses/ComponentAnalysesPage";
import RealtimeAnalysesPage from "./pages/analyses/componentAnalyses/RealtimeAnalysesPage";
import UserRealtimeAnalysesPage from "./pages/analyses/userAnalyses/UserRealtimeAnalysesPage";
import UserAnalysesPage from "./pages/analyses/userAnalyses/UserAnalysesPage";
import BentoRealtimeAnalysesPage from "./pages/analyses/bentoAnalyses/BentoRealtimeAnalysesPage";
import BentoProjectAnalysesPage from "./pages/analyses/bentoAnalyses/BentoProjectAnalysesPage";
import BentoVersionAnalysesPage from "./pages/analyses/bentoAnalyses/BentoVersionAnalysesPage";
import PullRequestsAnalysesPage from "./pages/analyses/PullRequestsAnalysesPage";
import BentoV2Page from "./pages/bentoV2/BentoV2Page";
import BentoTemplatePage from "./pages/bentoV2/BentoTemplatePage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export default function MyRouter() {
  const { isAuthenticated } = useContext(AuthContext);
  const { theme, bp } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "rows",
        backgroundColor: "rgba(256,0,0,.1)",
      }}
    >
      {/* {isAuthenticated && <CPanelSidebar />} */}
      {isAuthenticated && !bp?.md && <CPanelSidebar />}

      <Box
        sx={{
          width: "100%",
          minHeight: "max(100vh, 750px)",
          height: "100%",

          backgroundColor: theme.backgroundColor,
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
        }}
      >
        {isAuthenticated === null ? (
          <LoadingPage fullSize={true} />
        ) : isAuthenticated ? (
          <MainRouter />
        ) : (
          <RootRouter />
        )}
      </Box>
    </Box>
  );
}

const MainRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<DashboardPage />} />

      <Route
        exact
        path="/analyses/components/yearly"
        element={<YearlyComponentAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/components/monthly"
        element={<MonthlyComponentAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/components/daily"
        element={<DailyComponentAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/components/count-per-user"
        element={<CountPerUserPage />}
      />
      <Route
        exact
        path="/analyses/components/realtime"
        element={<RealtimeAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/components/statistics"
        element={<ComponentAnalysesPage />}
      />
      {/*  */}
      {/*  */}
      {/*  */}
      <Route
        exact
        path="/analyses/users/yearly"
        element={<YearlyUserAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/users/monthly"
        element={<MonthlyUserAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/users/daily"
        element={<DailyUserAnalysesPage />}
      />

      <Route
        exact
        path="/analyses/users/realtime"
        element={<UserRealtimeAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/users/statistics"
        element={<UserAnalysesPage />}
      />

      {/*  */}
      {/*  */}
      {/*  */}

      <Route
        exact
        path="/analyses/bento/realtime"
        element={<BentoRealtimeAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/bento/projects"
        element={<BentoProjectAnalysesPage />}
      />
      <Route
        exact
        path="/analyses/bento/versions"
        element={<BentoVersionAnalysesPage />}
      />

      {/*  */}
      {/*  */}
      {/*  */}

      <Route exact path="/products" element={<ProductsPage />} />

      <Route exact path="/feedback" element={<FeedbackPage />} />
      <Route exact path="/feedback/:feedbackId" element={<FeedbackPage />} />
      <Route exact path="/partners" element={<PartnersPage />} />
      <Route exact path="/forks" element={<ForkAnalysesPage />} />
      <Route
        exact
        path="/pullRequests"
        element={<PullRequestsAnalysesPage />}
      />

      <Route
        exact
        path="/newsletter/subscribers"
        element={<NewsletterSubscriberPage />}
      />

      <Route exact path="/settings" element={<SettingsPage />} />

      <Route exact path="/testimonials" element={<TestimonialPage />} />

      <Route exact path="/bento-featured" element={<BentoFeatured />} />

      <Route exact path="/users" element={<UsersPage />} />
      <Route exact path="/bento-v2" element={<BentoV2Page />} />
      <Route
        exact
        path="/bento-v2/:templateId"
        element={<BentoTemplatePage />}
      />

      <Route path="*" element={<Custom404Page />} />
    </Routes>
  );
};

const RootRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />

      <Route exact path="/signup" element={<SellerSignupPage />} />
      <Route exact path="/signup/confirm" element={<ConfirmEmailPage />} />

      <Route exact path="/login" element={<LoginPage />} />
      <Route path="*" element={<Custom404Page />} />
    </Routes>
  );
};

// const BuildPage = () => {
//   return (
//     <div>
//       <p>Build Number: 1.0.3</p>
//     </div>
//   );
// };
