import { Box, Checkbox, Tooltip } from "@mui/material";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import ReplyIcon from "@mui/icons-material/Reply";
import { useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";

export default function FeedbackReply({
  theme,
  feedbackMessages,
  getSingleFeedback,
}) {
  const [loading, setLoading] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [isSendEmail, setIsSendEmail] = useState(null);

  useEffect(() => {
    setIsSendEmail((e) => {
      if (e === null) {
        return !feedbackMessages?.createdBy ||
          feedbackMessages?.createdBy === undefined
          ? true
          : false;
      } else {
        return e;
      }
    });
  }, [feedbackMessages]);
  const handleReply = () => {
    setLoading(true);
    httpClient()
      .put(`/admin/feedback/${feedbackMessages?._id}`, {
        text: replyMessage,
        type: "reply",
        sendEmail: isSendEmail,
      })
      .then((res) => {
        console.log("Single Data", res.data);
        getSingleFeedback(feedbackMessages?._id);
        setReplyMessage("");
        setLoading(false);
        // getData();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <Box
      // onClick={() => handleReply(feedbackMessages[0]?._id)}
      sx={{
        // maxHeight: "72px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        p: 1,
        width: "100%",
        // cursor: "pointer",
        color: theme.textColor,
        backgroundColor: theme.ui2 + 40,
        "&:hover": {
          backgroundColor: theme.ui2 + 70,
        },
      }}
    >
      {/* <ReplyIcon />
  <Typography variant="h5" sx={{ color: theme.textColor }}>
    Reply
  </Typography> */}
      <Tooltip title="Send Email" arrow>
        <Checkbox
          checked={isSendEmail}
          onClick={() => setIsSendEmail((e) => !e)}
          size="small"
        />
      </Tooltip>
      <PDInput
        value={replyMessage}
        placeholder="Reply...."
        onChange={(e) => setReplyMessage(e.target.value)}
      />

      <PDButton
        loading={loading}
        sx={{ maxWidth: "100px" }}
        onClick={() => handleReply()}
      >
        <ReplyIcon />
        SEND
      </PDButton>
      {/* <Box
    sx={{
      width: "100%",
      maxWidth: "fit-content",
      pr: 1.5,

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  > */}

      {/* <Typography
      variant="p1"
      sx={{
        color: theme.textColor,
        width: "100%",
        maxWidth: "fit-content",
      }}
    >
      Send Email
    </Typography> */}
      {/* </Box> */}
    </Box>
  );
}
