import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { AuthContext, ThemeContext } from "src/context";
import { useForm } from "react-hook-form";

import PDButton from "src/components/_pdStyle/PDButton";

const url =
  process.env.REACT_APP_NODE_ENV === "development"
    ? "http://localhost:3000/login?source=admin&logout=true"
    : `https://app.jutsu.ai/login?source=admin&logout=true`;

export default function LoginPage() {
  const { loginWithEmail } = useContext(AuthContext);
  const { theme, bp } = useContext(ThemeContext);

  const { handleSubmit } = useForm();

  const onSubmit = (data) => loginWithEmail(data.email, data.password);

  return (
    <Box
      className="container"
      sx={{
        backgroundColor: bp?.sm ? theme.ui : theme.backgroundColor,
        minHeight: "max(100vh, 700px)",
      }}
    >
      <form
        className="container"
        style={{
          backgroundColor: theme.ui,
          height: bp?.sm ? "100vh" : "100%",
          maxWidth: bp?.sm ? "100%" : 450,
          width: "100%",
          padding: 32,
          border: bp?.sm ? "none" : `1px ${theme.borderColor} solid`,
          borderRadius: 4,
          gap: 30,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography variant="h2" sx={{ color: theme.textColor }}>
            LOGIN
          </Typography>
          <Typography variant="h6" sx={{ color: theme.textColor3 }}>
            Login to Admin Dashboard.
          </Typography>
        </Box>
        <a href={url} style={{ width: "100%" }}>
          <PDButton sx={{ width: "100%", px: 2 }}>Click Here</PDButton>
        </a>
      </form>
    </Box>
  );
}
