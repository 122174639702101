import { AuthContext, ThemeContext } from "src/context";
import { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CPanelContainer from "../../components/CPanelContainer";
import { useEffect } from "react";
import httpClient from "src/libs/httpClient";
import moment from "moment";
import InfoCard from "src/components/InfoCard";
import { Bar, Line } from "react-chartjs-2";
import PDDateRangePicker from "src/components/_pdStyle/PDDateRangePicker";
import capitalizeWords from "src/libs/capitalizeWords";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Jutsu's Monthly Component Analysis",
    },
  },
};

export default function AdminDashboardPage() {
  const { user } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  // const [cookies] = useCookies(["_auth_accessToken"]);
  // const jwtToken = cookies._auth_accessToken;

  const [grouping, setGrouping] = useState("default"); // ['default', 'hourly', 'daily', 'weekly', 'monthly']

  const groups = ["hourly", "daily", "weekly", "monthly"]; // "default"

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  const [activeUsers, setActiveUsers] = useState([]);
  const [dailyActiveUsers, setDailyActiveUsers] = useState([]);

  useEffect(() => {
    getActiveUsers();
  }, []);

  useEffect(() => {
    getDailyActiveUsers();
  }, [startDate, endDate, grouping]);

  const getActiveUsers = () => {
    httpClient()
      .get(`/admin/users/activeUsers`)
      .then((res) => {
        const data = res.data;
        console.log(data);
        setActiveUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDailyActiveUsers = () => {
    httpClient()
      .get(
        `/admin/users/dailyActiveUsers?startDate=${startDate}&endDate=${endDate}&grouping=${grouping}`
      )
      .then((res) => {
        const data = res.data;
        console.log("Daily Active Users : ", data);
        setDailyActiveUsers(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPanelContainer title="Dashboard" sx={{ p: 1 }}>
      <Box sx={{ pb: 2.5 }}>
        <Typography
          variant="h5"
          sx={{ color: theme.textColor2, fontWeight: 400 }}
        >
          Welcome Back,
        </Typography>
        <Typography
          variant="h2"
          sx={{ color: theme.textColor, fontWeight: 600 }}
        >
          {`${user?.name || user?.userName} `}👏🏻
        </Typography>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              backgroundColor: theme.ui,
              color: theme.textColor,
              padding: 2,
              borderRadius: 2,
              gap: 1,

              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <ButtonGroup
                aria-label="outlined primary button group"
                onClick={(e) => {
                  setGrouping(e.target.value);
                  console.log(e.target.value);
                }}
              >
                {groups?.map((group) => (
                  <Button
                    // onClick={() => setGrouping(group)}
                    value={group}
                    sx={{
                      backgroundColor:
                        grouping === group
                          ? theme.buttonColor
                          : theme.buttonColor + 33,
                      color:
                        grouping === group
                          ? theme.buttonTextColor
                          : theme.textColor2,
                    }}
                  >
                    {/* Daily */}
                    {capitalizeWords(group)}
                  </Button>
                ))}

                {/* <Button
                  value="weekly"
                  sx={{
                    backgroundColor:
                      grouping === "weekly"
                        ? theme.buttonColor
                        : theme.buttonColor + 33,
                    color:
                      grouping === "weekly"
                        ? theme.buttonTextColor
                        : theme.textColor2,
                  }}
                >
                  Weekly
                </Button>
                <Button
                  value="monthly"
                  sx={{
                    backgroundColor:
                      grouping === "monthly"
                        ? theme.buttonColor
                        : theme.buttonColor + 33,
                    color:
                      grouping === "monthly"
                        ? theme.buttonTextColor
                        : theme.textColor2,
                  }}
                >
                  Monthly
                </Button> */}
              </ButtonGroup>
            </Box>

            <Box>
              <PDDateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </Box>
          </Box>

          <Line
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "600px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={{
              labels: dailyActiveUsers?.map((d) =>
                new Date(d.label).toLocaleDateString()
              ),
              datasets: [
                {
                  label: "Daily Active Users",
                  data: dailyActiveUsers?.map((d) => d.averageUsers),
                  backgroundColor: "red",
                  borderColor: "red",

                  // ...chartData?.datasets[0],
                  // data:
                  //   chartData?.datasets?.length > 0
                  //     ? getCumulativeCounts(chartData?.datasets[0]?.data)
                  //     : [],
                },
              ],
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box>
            <InfoCard
              title="Current Active Users"
              value={activeUsers?.length}
            />
          </Box>

          <TableContainer
            sx={{
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 2,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Last Activity</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {activeUsers?.map((user) => (
                  <TableRow
                    key={user._id}
                    sx={{
                      cursor: "pointer",
                      "&:nth-of-type(odd)": {
                        backgroundColor: theme.textColor + "11",
                      },
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          gap: 2,
                        }}
                      >
                        <Avatar
                          src={user.avatar}
                          alt={user.userName || user?.name || user.email}
                        />
                        <p>{user.userName || user?.name || user.email}</p>
                      </Box>
                    </TableCell>

                    <TableCell>
                      {moment(user.lastActiveDate).fromNow()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </CPanelContainer>
  );
}
