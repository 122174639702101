import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CPanelContainer from "src/components/CPanelContainer";
import httpClient from "src/libs/httpClient";

export default function BentoV2Page() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    httpClient(process.env.REACT_APP_COPILOT_URL)
      .get("/craft/template")
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPanelContainer
      title={`Bento v2`}
      sx={{
        p: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        }}
      >
        {data?.map((item, index) => (
          <Button
            key={index}
            component={Link}
            to={`/bento-v2/${item._id}`}
            sx={{
              border: "1px solid #ccc",
              borderRadius: 1,
              flexDirection: "column",
              display: "flex",

              justifyContent: "space-between",
              padding: 0,
              margin: 0,
            }}
          >
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: 1,
                aspectRatio: 1 / 1,
                objectPosition: "top",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              }}
              src={item.image}
              alt={item.name}
            />

            <Box
              sx={{
                padding: 1,
                display: "flex",
                gap: 1,
                flexDirection: "column",
                color: "#FFFFFF",
                textAlign: "left",
                mt: 1,
                textTransform: "none",
              }}
            >
              <Typography className="max1Lines" variant="h4">
                {item.name}
              </Typography>
              <Typography
                variant="body2"
                className="max2Lines"
                style={{
                  opacity: 0.7,
                }}
              >
                {item.description}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: 1,
                }}
              >
                <Avatar src={item.createdBy.avatar} alt={item.createdBy.name} />

                <Typography variant="p2">
                  {item.createdBy.name ||
                    item.createdBy.userName ||
                    item.createdBy.email}
                </Typography>
              </Box>
            </Box>
          </Button>
        ))}
      </Box>
    </CPanelContainer>
  );
}
