import { Box, CircularProgress, Typography } from "@mui/material";
import FeedbackConversation from "./FeedbackConversation";
import FeedbackHeader from "./FeedbackHeader";
import FeedbackReply from "./FeedbackReply";
import { useContext, useEffect, useState } from "react";
import httpClient from "src/libs/httpClient";
import { ThemeContext } from "src/context";

export default function FeedbackFullview({
  theme,
  getFeedbacks,
  feedbackId,
  isOpened,
}) {
  // const { theme } = useContext(ThemeContext);
  const [firstTimeView, setFirstTimeView] = useState(false);
  const [feedbackMessages, setFeedbackMessages] = useState("");
  const [feedbackChats, setFeedbackChats] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("pending");

  useEffect(() => {
    if (feedbackId) setFirstTimeView(false);
    getSingleFeedback(feedbackId);

    let feedbackInterval = setInterval(() => {
      setFirstTimeView(true);
      getSingleFeedback(feedbackId);
      getFeedbacks();
      // getFeedbacks(!firstTimeView);
    }, 10000);
    return () => clearInterval(feedbackInterval);
  }, [feedbackId]);

  const getSingleFeedback = (id) => {
    setLoading(true);
    httpClient()
      .get(`/admin/feedback/${id}`)
      .then((res) => {
        console.log(res.data);

        const array = [
          {
            _id: res?.data?._id,
            from: "customer",
            text: res?.data?.initialMessage?.text,
            timestamp: res?.data?.initialMessage?.timestamp,
          },
          ...res?.data?.replies,
        ];

        setFeedbackMessages(res?.data);
        setFeedbackChats(array?.reverse());
        setFirstTimeView(true);
        setStatus(res?.data?.status);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return loading && !firstTimeView ? (
    <CircularProgress />
  ) : isOpened === true && feedbackMessages && feedbackMessages.length !== 0 ? (
    <>
      <FeedbackHeader
        theme={theme}
        setFeedbacks={setFeedbacks}
        setFirstTimeView={setFirstTimeView}
        feedbackMessages={feedbackMessages}
        setFeedbackMessages={setFeedbackMessages}
        getFeedbacks={getFeedbacks}
        status={status}
        setStatus={setStatus}
      />
      <FeedbackConversation
        theme={theme}
        feedbackMessages={feedbackMessages}
        setFeedbackMessages={setFeedbackMessages}
        getSingleFeedback={getSingleFeedback}
        feedbackChats={feedbackChats}
      />
      <FeedbackReply
        theme={theme}
        feedbackMessages={feedbackMessages}
        getSingleFeedback={getSingleFeedback}
      />
    </>
  ) : (
    <Typography variant="h2" sx={{ color: theme.textColor + 10 }}>
      Empty feedback
    </Typography>
  );
}
