import { Box, Typography } from "@mui/material";
import moment from "moment";

export default function FeedbackMessage({
  theme,
  feedbackMessages,
  textMessage,
  timeStamp,
  repliesFrom,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        backgroundColor: theme.ui2,
        width: "fit-content",
        marginLeft: repliesFrom === "admin" ? "auto" : "unset",
        px: 1,
        py: 0.5,
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.buttonColor,
            fontWeight: 600,
            fontSize: "13px",
          }}
        >
          {repliesFrom === "admin"
            ? "Jutsu Support"
            : feedbackMessages?.createdBy?.name ||
              feedbackMessages?.email ||
              feedbackMessages?.createdBy?.email ||
              feedbackMessages?.createdBy?.userName ||
              "NaN"}
        </Typography>
        <Typography
          variant="p2"
          sx={{
            color: theme.textColor3,
          }}
        >
          {moment(timeStamp).fromNow()}
        </Typography>{" "}
      </Box>
      <Typography
        variant="p"
        sx={{
          color: theme.textColor,
        }}
      >
        {textMessage}
      </Typography>
    </Box>
  );
}
