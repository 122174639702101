import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Avatar,
  IconButton,
  Paper,
  TableContainer,
  Fade,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ThemeContext } from "src/context";
import { useContext, useState } from "react";
import httpClient from "src/libs/httpClient";
import PartnersDialog from "src/dialogs/PartnersDialog";
import { toast } from "sonner";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// tableCell: { p: 0, py: 1, px: 1 },
const styles = {
  tableCell: { py: 1, px: 2 },
};
let dragStartIndex;
export default function PartnersTable({
  partners,
  getData,
  loading,
  perPage,
  setHasChangedPosition,
  setPartners,
}) {
  const { theme } = useContext(ThemeContext);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(partners);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dragStartIndex === result.destination.index
      ? setHasChangedPosition(false)
      : setHasChangedPosition(true);
    setPartners(items);
  };

  return (
    <DragDropContext
      onDragStart={(e) => {
        return (dragStartIndex = e.source.index);
      }}
      onDragEnd={onDragEnd}
    >
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: `1px ${theme.borderColor} solid`,
          // border: "1px solid red",
          borderTop: "none",
          mb: 2,
        }}
      >
        {loading ? (
          <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <div
              style={{
                width: "100%",
                height: 40.5,
                borderRadius: ".25rem",
                backgroundColor: theme.ui,
              }}
            />
            {Array.from(new Array(perPage)).map((item, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  height: 72,
                  borderRadius: ".25rem",
                  backgroundColor: theme.ui,
                }}
              />
            ))}
          </div>
        ) : (
          <Fade in={!loading}>
            <div>
              {partners?.length > 0 ? (
                <Table
                  sx={{
                    minWidth: 650,
                    backgroundColor: theme.ui,
                    // "& th": {
                    //   borderBottom: `1px ${theme.borderColor} solid`,
                    // },
                    // height: 60,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.tableCell}></TableCell>
                      <TableCell sx={styles.tableCell}></TableCell>
                      <TableCell sx={styles.tableCell}>
                        <Typography
                          variant="h6"
                          sx={{ color: theme.textColor, fontWeight: 600 }}
                        >
                          Name
                        </Typography>
                      </TableCell>

                      <TableCell align="left" sx={styles.tableCell}>
                        <Typography
                          variant="h6"
                          sx={{ color: theme.textColor, fontWeight: 600 }}
                        >
                          Description
                        </Typography>
                      </TableCell>

                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>

                  <Droppable droppableId="partnersList">
                    {(provided) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {partners?.map((data, index) => (
                          <Draggable
                            key={data?._id}
                            draggableId={data?._id}
                            index={index}
                          >
                            {(provided) => (
                              <TableItem
                                key={index}
                                provided={provided}
                                data={data}
                                getData={getData}
                              />
                              // </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    py: 10,
                    px: 2,
                  }}
                >
                  <Typography align="center" sx={{ color: theme.textColor3 }}>
                    You don&apos;t have any partners.
                  </Typography>
                </Box>
              )}
            </div>
          </Fade>
        )}
      </TableContainer>
    </DragDropContext>
  );
}

const TableItem = ({ data, getData, provided }) => {
  const { theme } = useContext(ThemeContext);

  const [open, setOpen] = useState(false);

  const [isPublicChecked, setIsPublicChecked] = useState(data?.isPublic);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    httpClient()
      .delete(`/admin/companyPartners/${data._id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVisibility = (isChecked) => {
    setIsLoading(true);
    toast("Updating partners...", { type: "info" });

    httpClient()
      .put(`/admin/companyPartners/${data._id}`, { isPublic: isChecked })
      .then((res) => {
        getData();
        setIsPublicChecked((e) => !e);
        setIsLoading(false);
        toast("Partners updated", { type: "success" });
      })
      .catch((err) => {
        setIsPublicChecked(false);
        setIsLoading(false);
        toast(err.response.data.message || "Failed to update partners", {
          type: "error",
        });
      });
  };

  return (
    <TableRow
      {...provided.draggableProps}
      ref={provided.innerRef}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": { backgroundColor: theme.ui2 + 33 },
        "& td": {
          borderBottom: `1px ${theme.borderColor} solid`,
        },
        opacity: isPublicChecked ? 1 : 0.6,
      }}
    >
      <TableCell {...provided.dragHandleProps}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.8,
          }}
        >
          <DragIndicatorIcon />
        </Box>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 37.98,
            height: 37.98,
          }}
        >
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <IconButton onClick={() => handleVisibility(!isPublicChecked)}>
              {isPublicChecked ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </IconButton>
          )}
        </Box>
      </TableCell>
      <TableCell sx={styles.tableCell}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            src={`${process.env.REACT_APP_CDN_URL}/${data?.logo}`}
            alt={data?.name}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              ml: 1,
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="p1">{data?.name}</Typography>
            <Typography
              variant="p2"
              sx={{
                color: theme.textColor3,
                fontWeight: 400,
              }}
            >
              /{data?.slug}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          ...styles.tableCell,
        }}
      >
        <span className="max1Lines">{data.markdown}</span>
      </TableCell>
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            gap: 1,
            m: -0.5,
          }}
        >
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon
              sx={{
                cursor: "pointer",
                color: theme.buttonColor,
                // mr: 1,
                display: "block",
              }}
            />
          </IconButton>

          <IconButton onClick={() => handleDelete()}>
            <DeleteIcon
              sx={{
                display: "block",
                cursor: "pointer",
                color: theme.accent.red,
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
      {open && (
        <PartnersDialog
          open={open}
          setOpen={setOpen}
          data={data}
          getData={getData}
        />
      )}
    </TableRow>
  );
};
