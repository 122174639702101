import { useContext } from "react";
import { Box } from "@mui/material";
import { ThemeContext } from "src/context";
import Header from "src/components/header/Header";
import CPanelBottomBar from "./CPanelBottomBar";

export default function CPanelContainer(props) {
  const { title, subTitle, children, sx } = props;

  const { bp } = useContext(ThemeContext);

  return (
    <>
      <Header title={title} subTitle={subTitle} />

      <Box
        sx={{
          ...sx,
          width: "calc(100% - 16px)",
          maxWidth: "max(calc(100vw - 307px), 1500px)",
          p: 2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: 2,
        }}
      >
        {children}
      </Box>

      {bp?.md && <CPanelBottomBar />}
    </>
  );
}
