import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import { useContext, useState } from "react";
import { AuthContext, ThemeContext } from "src/context";
import { Link, useLocation } from "react-router-dom";

import { adminMenuPrimary } from "src/data/AdminMenuData";

const drawerWidth = 275;

export default function CPanelSidebar({ variant }) {
  const { theme, showSidebar, setShowSidebar } = useContext(ThemeContext);
  const { user, logout } = useContext(AuthContext);

  return (
    <Drawer
      // "rgb(0, 46, 28)"
      PaperProps={{
        sx: {
          backgroundColor: theme.ui || theme.buttonColor2,
          color: "#FFF",
          justifyContent: "space-between",
          borderRight: `1px solid ${theme.borderColor}`,
        },
      }}
      variant="permanent"
      open={showSidebar}
    >
      <Box>
        <Box
          sx={{
            height: 60,
            pl: 1.5,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <IconButton onClick={() => setShowSidebar((e) => !e)}>
            <MenuIcon fontSize="small" sx={{ fill: theme.textColor }} />
          </IconButton>

          <Link to="/" style={{ cursor: "pointer" }}>
            <img
              style={{ height: 25, opacity: showSidebar ? 1 : 0 }}
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE3MyIgaGVpZ2h0PSIxMTczIiB2aWV3Qm94PSIwIDAgMTE3MyAxMTczIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04NzkgNjU4Qzg3OC44NjggNzM1LjM5NSA4NDguMDEyIDgwOS41OTIgNzkzLjE4MiA4NjQuMzI5QzczOC4yMzQgOTE5LjE4MyA2NjMuNzA4IDk1MCA1ODYgOTUwQzUwOC4yOTIgOTUwIDQzMy43NjYgOTE5LjE4MyAzNzguODE4IDg2NC4zMjlDMzIzLjg3IDgwOS40NzQgMjkzIDczNS4wNzYgMjkzIDY1Ny41VjU0Nkg0NDYuMjVWNjU3LjVMNDQ2LjI1MSA2NTcuNUM0NDYuMjUxIDY5NC41MDEgNDYwLjk3NCA3MjkuOTg2IDQ4Ny4xODIgNzU2LjE0OUM1MTMuMzkgNzgyLjMxMiA1NDguOTM2IDc5Ny4wMTEgNTg2IDc5Ny4wMTFDNjIzLjA2NCA3OTcuMDExIDY1OC42MSA3ODIuMzEyIDY4NC44MTggNzU2LjE0OUM3MTEuMDI2IDcyOS45ODYgNzI1Ljc0OSA2OTQuNTAxIDcyNS43NDkgNjU3LjVINzI2VjM2NC4yNUwyOTQgMzY0LjI1TDI5NCAyMTFMNzI2IDIxMUg4NzlWMzY0LjI1VjY1Ny41TDg3OSA2NTcuNjY3Qzg3OSA2NTcuNzc4IDg3OSA2NTcuODg5IDg3OSA2NThaIiBmaWxsPSJ3aGl0ZSIvPg0KPGNpcmNsZSBjeD0iMzcwLjA5OCIgY3k9IjQ1NS4xNTMiIHI9IjQ4LjgwMTIiIGZpbGw9IiNGRkQzN0MiLz4NCjwvc3ZnPg0K"
              alt="Poket Dokan"
            />
          </Link>
        </Box>

        <List sx={{ p: 0 }}>
          {adminMenuPrimary.map((item, index) => {
            if (item.role && item.role !== user?.role) return null;
            return (
              <Link key={index} to={item.to} style={{ textDecoration: "none" }}>
                <CustomListItem {...item} />
              </Link>
            );
          })}
        </List>
      </Box>

      <Box>
        <Divider sx={{ backgroundColor: "rgba(255,255,255,.15)" }} />
        <Link to="/settings" style={{ textDecoration: "none" }}>
          <CustomListItem
            label="Setting"
            to="/settings"
            icon={<SettingsIcon sx={{ fill: "currentcolor" }} />}
          />
        </Link>

        <CustomListItem
          label="Logout"
          icon={<LogoutRoundedIcon />}
          onClick={() => logout()}
        />
      </Box>
    </Drawer>
  );
}

const CustomListItem = ({ label, icon, to, onClick, children, sx }) => {
  const { pathname } = useLocation();
  const { theme, showSidebar } = useContext(ThemeContext);

  const [isOpened, setIsOpened] = useState(true);

  const isSelected =
    pathname === "/" && to === "/"
      ? true
      : to?.length > 1 && pathname?.includes(to)
      ? true
      : false;

  return (
    <>
      <Tooltip
        disableHoverListener={showSidebar}
        title={label}
        placement="right"
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: showSidebar ? "initial" : "center",
            px: 2.5,

            backgroundColor: isSelected
              ? theme.buttonColor + `22 !important`
              : "transparent",
            transition: "all 0.1s ease-in-out",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, .15)",
            },
            "*": {
              fill: isSelected ? theme.buttonColor : theme.textColor2,
              color: isSelected ? theme.buttonColor : theme.textColor2,
            },

            ...sx,
          }}
          onClick={() =>
            children ? setIsOpened((e) => !e) : onClick && onClick()
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              // mr: showSidebar ? 1.5 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography variant="h6" sx={{ ml: 1.5, fontWeight: 500 }}>
                {label}
              </Typography>
            }
            sx={{
              transition: "all .2s ease-in-out",
              opacity: showSidebar ? 1 : 0,
            }}
          />

          {children && (
            <KeyboardArrowDownRoundedIcon
              sx={{
                fill: theme.textColor2,
                transition: "all .2s ease-in-out",
                transform: isOpened ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          )}
        </ListItemButton>
      </Tooltip>

      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        {children &&
          children.map((item, index) => (
            <Link key={index} to={item.to} style={{ textDecoration: "none" }}>
              <CustomListItem {...item} sx={{ pl: 6 }} />
            </Link>
          ))}
      </Collapse>
    </>
  );
};

// UI Stuff
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
