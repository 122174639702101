import DeleteIcon from "@mui/icons-material/Delete";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import httpClient from "src/libs/httpClient";
import ActionDialog from "src/dialogs/ActionDialog";
import { useHref } from "react-router-dom";

const statusColor = {
  closed: "#FF0000",
  pending: "#FFA500",
  open: "#008000",
};

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="warning" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="tertiary" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

export default function FeedbackHeader({
  theme,
  feedbackMessages,
  setFeedbackMessages,
  getFeedbacks,
  status,
  setStatus,
}) {
  const [loading, setLoading] = useState(false);

  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const handleStatusChange = (stats) => {
    setLoading(true);
    setStatus(stats);
    httpClient()
      .put(`/admin/feedback/${feedbackMessages?._id}`, {
        status: stats,
        type: "status",
      })
      .then((res) => {
        console.log("Single Data", res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setStatus((e) => e);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    httpClient()
      .delete(`/admin/feedback/${feedbackMessages?._id}`)
      .then((res) => {
        console.log("Single Data", res.data);
        window.history.pushState({}, "", "/feedbacks");
        setFeedbackMessages("");
        getFeedbacks();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          // position: "absolute",
          // top: 0,
          // left: 0,
          backgroundColor: theme.ui2 + 40,
          width: "100%",
          maxHeight: "72px",
          p: 2,
          gap: 1,
          borderBottom: `1px ${theme.borderColor} solid`,
        }}
      >
        <img
          src={
            feedbackMessages?.createdBy?.avatar ||
            `https://source.boringavatars.com/pixel/40/${
              feedbackMessages?.createdBy?.email ||
              feedbackMessages?.email ||
              feedbackMessages?.createdBy?.userName
            }?colors=0A0310,49007E,FF005B,FF7D10,FFB238`
          }
          alt="not found"
          style={{ width: 40, height: 40, borderRadius: 50 }}
        />

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" sx={{ color: theme.textColor2 }}>
            {feedbackMessages?.createdBy?.name ||
              feedbackMessages?.email ||
              feedbackMessages?.createdBy?.email ||
              feedbackMessages?.createdBy?.userName ||
              "NaN"}
            {feedbackMessages?.createdBy === undefined ? " (guest)" : ""}
          </Typography>
          <Typography variant="p2" style={{ color: theme.textColor3 }}>
            {moment(feedbackMessages?.updatedAt).fromNow()}
          </Typography>
        </Box>

        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          {feedbackMessages?.createdBy !== undefined && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: theme.textColor2,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  gap: 0.6,
                }}
              >
                Rating:{" "}
                <Tooltip
                  placement="bottom"
                  arrow
                  title={customIcons[feedbackMessages?.score].label}
                >
                  {customIcons[feedbackMessages?.score].icon}
                </Tooltip>
              </Typography>
              {/* <div style={{width}}/> */}
              <div
                style={{
                  height: "18px",
                  backgroundColor: theme.textColor3,
                  width: "1px",
                }}
              />
              <Typography
                variant="h5"
                sx={{ color: theme.textColor2, fontWeight: 600 }}
              >
                Status:
              </Typography>
              <Select
                sx={{
                  "& .MuiSelect-select": {
                    fontSize: "12px",
                    padding: "0px 10px",
                    backgroundColor: statusColor[status] + 80,
                  },
                  "& .MuiSelect-root": {
                    backgroundImage: "none",
                  },
                }}
                value={status}
                onChange={(e) => handleStatusChange(e.target.value)}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </Select>
            </Box>
          )}
          <IconButton onClick={() => setShowConfirmButton(true)}>
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </Box>
      </Box>
      <ActionDialog
        open={showConfirmButton}
        setOpen={setShowConfirmButton}
        title="Delete User"
        description={`Are you sure you want to delete this user? This action is irreversible.`}
        primaryButtonLabel="Delete"
        secondaryButtonLabel="Cancel"
        secondaryButtonLoading={loading}
        primaryButtonOnClick={() => {
          handleDelete();
          setShowConfirmButton(false);
        }}
      />
    </>
  );
}
