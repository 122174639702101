import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { RiFeedbackFill } from "react-icons/ri";
import { FaCodePullRequest } from "react-icons/fa6";
import { BiGitRepoForked } from "react-icons/bi";
import { ImNewspaper } from "react-icons/im";
import { FaHandshake } from "react-icons/fa";
import { LuMessagesSquare } from "react-icons/lu";
import { FaStar } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";

export const adminMenuPrimary = [
  {
    id: 1,
    label: "Dashboard",
    to: "/",
    icon: <DashboardRoundedIcon fontSize="small" />,
  },
  {
    id: 2,
    label: "Component Analyses",
    icon: <InsightsRoundedIcon fontSize="small" />,
    children: [
      {
        id: 241,
        label: "Realtime",
        icon: <CalendarMonthRoundedIcon fontSize="small" />,
        to: "/analyses/components/realtime",
      },
      {
        id: 211,
        label: "Component Statistics",
        icon: <CalendarTodayRoundedIcon fontSize="small" />,
        to: "/analyses/components/statistics",
      },
      {
        id: 24,
        label: "Count Per User",
        icon: <CalendarMonthRoundedIcon fontSize="small" />,
        to: "/analyses/components/count-per-user",
      },

      {
        id: 21,
        label: "Daily Analyses",
        icon: <CalendarTodayRoundedIcon fontSize="small" />,
        to: "/analyses/components/daily",
      },
      {
        id: 22,
        label: "Monthly Analyses",
        icon: <DateRangeRoundedIcon fontSize="small" />,
        to: "/analyses/components/monthly",
      },
      {
        id: 23,
        label: "Yearly Analyses",
        icon: <CalendarMonthRoundedIcon fontSize="small" />,
        to: "/analyses/components/yearly",
      },
    ],
  },
  {
    id: 3,
    label: "User Analyses",
    icon: <PeopleAltIcon fontSize="small" />,
    children: [
      {
        id: 341,
        label: "Realtime",
        icon: <CalendarMonthRoundedIcon fontSize="small" />,
        to: "/analyses/users/realtime",
      },
      {
        id: 311,
        label: "User Statistics",
        icon: <CalendarTodayRoundedIcon fontSize="small" />,
        to: "/analyses/users/statistics",
      },

      {
        id: 31,
        label: "Daily Analyses",
        icon: <CalendarTodayRoundedIcon fontSize="small" />,
        to: "/analyses/users/daily",
      },
      {
        id: 32,
        label: "Monthly Analyses",
        icon: <DateRangeRoundedIcon fontSize="small" />,
        to: "/analyses/users/monthly",
      },
      {
        id: 33,
        label: "Yearly Analyses",
        icon: <CalendarMonthRoundedIcon fontSize="small" />,
        to: "/analyses/users/yearly",
      },
    ],
  },
  {
    id: 43,
    label: "Bento Analyses",
    icon: <PeopleAltIcon fontSize="small" />,
    children: [
      {
        id: 4341,
        label: "Realtime",
        icon: <CalendarMonthRoundedIcon fontSize="small" />,
        to: "/analyses/bento/realtime",
      },
      {
        id: 4311,
        label: "Bento Projects",
        icon: <CalendarTodayRoundedIcon fontSize="small" />,
        to: "/analyses/Bento/projects",
      },
      {
        id: 4312,
        label: "Bento Versions",
        icon: <CalendarTodayRoundedIcon fontSize="small" />,
        to: "/analyses/Bento/versions",
      },
    ],
  },
  {
    id: 4,
    label: "Feedback",
    to: "/feedback",
    icon: <RiFeedbackFill />,
  },
  {
    id: 5,
    label: "Forks",
    to: "/forks",
    icon: <BiGitRepoForked />,
  },
  {
    id: 6,
    label: "Pull Requests",
    to: "/pullRequests",
    icon: <FaCodePullRequest />,
  },
  {
    id: 7,
    label: "Users",
    to: "/users",
    icon: <FaUsersCog />,
  },
  {
    id: 8,
    label: "Newsletter Subscribers",
    to: "/newsletter/subscribers",
    icon: <ImNewspaper />,
  },
  {
    id: 9,
    label: "Partners",
    to: "/partners",
    icon: <FaHandshake />,
  },
  {
    id: 10,
    label: "Testimonials",
    to: "/testimonials",
    icon: <LuMessagesSquare />,
  },
  {
    id: 11,
    label: "Bento Featured",
    to: "/bento-featured",
    icon: <FaStar />,
  },
  {
    id: 13,
    label: "Bento v2",
    to: "/bento-v2",
    icon: <FaStar />,
  },
];
