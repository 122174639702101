import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Backdrop,
  CircularProgress,
  Typography,
  Checkbox,
} from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PDButton from "src/components/_pdStyle/PDButton";
import PDInput from "src/components/_pdStyle/PDInput";
import PDDropzone from "src/components/_pdStyle/PDDropzone";
import httpClient from "src/libs/httpClient";
import { toast } from "sonner";
import uploadPhoto from "src/libs/uploadPhoto";
import { ThemeContext } from "src/context";
import deletePhoto from "src/libs/deletePhoto";

export default function TestimonalDialog({ open, setOpen, data, getData }) {
  const { theme, bp } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState([]);
  const [review, setReview] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState([]);
  const [position, setPosition] = useState("");
  const [visibility, setVisibility] = useState(false);

  const [oldAvatarURL, setOldAvatarURL] = useState("");
  const [oldCompanyLogoURL, setOldCompanyLogoURL] = useState("");

  useEffect(() => {
    if (data) {
      setName(data?.user?.name);
      setReview(data?.review);
      setCompanyName(data?.company?.name);
      setPosition(data?.company?.position);
      setVisibility(data?.isPublic);
      setOldAvatarURL(data?.user?.avatar);
      setOldCompanyLogoURL(data?.company?.logo);
    }
  }, [data]);

  const uploadAvatar = async () => {
    setLoading(true);
    await uploadPhoto(
      avatar[0],
      "testimonials/avatars",
      {
        width: 512,
        height: 512,
      },
      `${name}-avatar`
    ).then((url) => {
      if (companyLogo.length > 0) {
        uploadCompanyLogo({ avatarUrl: url });
      } else {
        handleSubmit({ avatarUrl: url });
      }
    });
  };

  const uploadCompanyLogo = async ({ avatarUrl }) => {
    setLoading(true);
    await uploadPhoto(
      companyLogo[0],
      "testimonials/companyLogos",
      {},
      `${companyName}-logo`
    ).then((url) => {
      handleSubmit({ avatarUrl: avatarUrl, companyLogoUrl: url });
    });
  };

  const handleSubmit = async ({
    avatarUrl = undefined,
    companyLogoUrl = undefined,
  }) => {
    console.log("submitted");

    if (!name || !review || !companyName || !companyLogo || !position) {
      toast("Fill all the fields", { type: "error" });
      return;
    }

    // if (avatar.length === 0) {
    //   toast("Upload an avatar", "error");
    //   return;
    // }

    // if (companyLogo.length === 0) {
    //   toast("Upload a company logo", "error");
    //   return;
    // }

    const dataToPost = {
      user: { name, avatar: avatarUrl },
      review: review,
      company: { name: companyName, logo: companyLogoUrl, position },

      isPublic: visibility,
    };

    console.log(data);

    setLoading(true);

    if (!data?._id) {
      httpClient()
        .post("/admin/testimonials", dataToPost)
        .then((res) => {
          console.log(res);

          setName("");
          setAvatar([]);
          setReview("");
          setCompanyName("");
          setCompanyLogo([]);
          setPosition("");
          setVisibility(true);

          toast("Testimonial added", { type: "success" });
          getData();
          setOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);
          setLoading(false);

          toast(err?.response?.data?.message || "Failed to add testimonial", {
            type: "error",
          });
        });
    } else {
      httpClient()
        .put(`/admin/testimonials/${data._id}`, dataToPost)
        .then((res) => {
          console.log(res);

          setName("");
          setAvatar([]);
          setReview("");
          setCompanyName("");
          setCompanyLogo([]);
          setPosition("");
          setVisibility(true);

          setLoading(false);
          toast("Testimonial updated", { type: "success" });
          getData();
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);

          setLoading(false);
          toast(err.response.data.message || "Failed to update testimonial", {
            type: "error",
          });
        });
    }
  };

  const deleteOldImage = (e) => {
    setLoading(true);

    console.log("deleteOldAvatar: ", e);
    deletePhoto(e).then((res) => {
      console.log(res);

      toast("Old image deleted", { type: "success" });
      console.log("oldAvatarURL: ", e);
      if (e === oldAvatarURL) {
        httpClient()
          .put(`/admin/testimonials/${data._id}`, {
            user: { avatar: "" },
          })
          .then((res) => {
            console.log(res);

            setAvatar([]);
            setOldAvatarURL("");
            setLoading(false);

            toast("Testimonial updated", { type: "success" });
          })
          .catch((err) => {
            console.log(err);
            setOpen(false);

            setLoading(false);
            toast(err.response.data.message || "Failed to update testimonial", {
              type: "error",
            });
          });
      } else if (e === oldCompanyLogoURL) {
        httpClient()
          .put(`/admin/testimonials/${data._id}`, {
            company: { logo: "" },
          })
          .then((res) => {
            console.log(res);

            setOldCompanyLogoURL("");
            setCompanyLogo([]);

            setLoading(false);
            toast("Testimonial updated", { type: "success" });
          })
          .catch((err) => {
            console.log(err);
            setOpen(false);
            setLoading(false);

            toast(err.response.data.message || "Failed to update testimonial", {
              type: "error",
            });
          });
      }
    });
  };

  const handleVisibility = () => {
    setVisibility((e) => !e);
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: 9999999999999999999 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen((e) => !e)}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialogContent-root": {
            overflowY: "unset",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "100%",
            backgroundColor: theme.backgroundColor,
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                sx={{
                  width: 40,

                  color: theme.buttonColor,
                  backgroundColor: theme.buttonColor + "22",
                }}
                onClick={() => setOpen((e) => !e)}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "100%",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Typography
                variant={bp?.sm ? "h4" : "h3"}
                sx={{ color: theme.textColor, fontWeight: 600 }}
              >
                Add testimonials information:
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: 600,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 4,
                  // paddingBlock: 4,
                }}
              >
                <PDInput
                  label="Full name"
                  type="text"
                  placeholder="Enter full name.."
                  required
                  sx={{ backgroundColor: theme.ui }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <PDDropzone
                  value={avatar}
                  setValue={setAvatar}
                  maxFiles={1}
                  label="Avatar"
                  oldFiles={oldAvatarURL?.length > 0 ? [oldAvatarURL] : []}
                  fileToDelete={(e) => deleteOldImage(e)}
                />
                <PDInput
                  label="Short massage.."
                  type="text"
                  required
                  placeholder="Enter the massage here.."
                  sx={{ backgroundColor: theme.ui }}
                  rows={4}
                  multiline
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
                <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                  <PDInput
                    label="Company name"
                    type="text"
                    placeholder="Enter Company name.."
                    required
                    sx={{ backgroundColor: theme.ui }}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <PDInput
                    label="Position"
                    type="text"
                    placeholder="Enter position name.."
                    sx={{ backgroundColor: theme.ui }}
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </Box>
                <PDDropzone
                  maxFiles={1}
                  required
                  label="Company logo"
                  value={companyLogo}
                  setValue={setCompanyLogo}
                  oldFiles={
                    oldCompanyLogoURL?.length > 0 ? [oldCompanyLogoURL] : []
                  }
                  fileToDelete={(e) => deleteOldImage(e)}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    // gap: 0.5,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: theme.textColor2, fontWeight: 500 }}
                  >
                    Is Public:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // gap: 0.5,
                      marginLeft: "-11px",
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={visibility}
                      onClick={handleVisibility}
                      color="primary"
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: visibility
                          ? theme.buttonColor
                          : theme.textColor3,
                      }}
                    >
                      {visibility ? "Visible to public" : "Hidden from public"}
                    </Typography>
                  </Box>
                </Box>
                <PDButton
                  type="submit"
                  sx={{ maxWidth: 150 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (avatar.length > 0) {
                      uploadAvatar();
                    } else if (avatar.length <= 0 && companyLogo.length > 0) {
                      uploadCompanyLogo({ avatarUrl: "" });
                    } else {
                      handleSubmit({ avatarUrl: "", companyLogoUrl: "" });
                    }
                  }}
                >
                  Submit
                </PDButton>{" "}
                {/* </Box> */}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
