import { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Bar, Line } from "react-chartjs-2";

import httpClient from "src/libs/httpClient";

import { ThemeContext } from "src/context";
import CPanelContainer from "src/components/CPanelContainer";
import InfoCard from "src/components/InfoCard";
import LoadingPage from "src/components/LoadingPage";

const generateColor = () => {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return `rgba(${r}, ${g}, ${b}, .9)`;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    title: {
      display: false,
      text: "Realtime Component Analyses (Last 24 Hours)",
    },
  },
};

export default function RealtimeAnalysesPage() {
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [rowData, setRowData] = useState([]);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    httpClient()
      .get(`/admin/analysis/components/last24hours`)
      .then((res) => {
        const data = res.data;
        console.log("getData -> ", data);
        setRowData(data.reverse());

        processChartData(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    httpClient()
      .get(`/admin/analysis/components/summary`)
      .then((res) => {
        const data = res.data;
        console.log(data);

        setSummary(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to process data into chart format
  const processChartData = (groupedData) => {
    const startHour = new Date(Date.now() - 24 * 60 * 60 * 1000);
    const chartLabels = Array.from({ length: 24 }, (_, i) => {
      const hour = new Date(startHour.getTime() + i * 60 * 60 * 1000);
      return `${hour.getUTCFullYear()}-${String(
        hour.getUTCMonth() + 1
      ).padStart(2, "0")}-${String(hour.getUTCDate()).padStart(
        2,
        "0"
      )} ${String(hour.getUTCHours()).padStart(2, "0")}:00`;
    });

    const sources = [...new Set(groupedData.map((item) => item._id.source))];

    const datasets = sources.map((source) => {
      const sourceData = chartLabels.map((label) => {
        const found = groupedData.find(
          (item) => item._id.hour === label && item._id.source === source
        );
        return found ? found.count : 0;
      });

      return {
        label: source,
        data: sourceData,
        backgroundColor: generateColor(),
      };
    });

    setChartData({
      labels: chartLabels?.map((label) => label.split(" ")[1]),
      datasets,
    });
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <CPanelContainer
      title="Realtime Component Analyses (Last 24 Hours)"
      sx={{ p: 1, width: "100%" }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Bar
            key="yearly-component-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "500px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={chartData}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 2,
            width: "100%",
          }}
        >
          <InfoCard
            title={`Total Components`}
            value={summary?.totalComponents?.toLocaleString()}
          />

          <InfoCard
            title={`Highest Grossing Month (${
              months[summary?.highestGrossingMonth?.month - 1]
            })`}
            value={summary?.highestGrossingMonth?.count?.toLocaleString()}
          />

          <InfoCard
            title={`Lowest Grossing Month (${
              months[summary?.lowestGrossingMonth?.month - 1]
            })`}
            value={summary?.lowestGrossingMonth?.count?.toLocaleString()}
          />

          <InfoCard
            title={`This Month's Total`}
            value={summary?.thisMonthsTotal.toLocaleString()}
          />
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
          backgroundColor: "#10182b" || theme.ui,
          color: theme.textColor,
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Source</TableCell>
              <TableCell align="center">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row?._id?.hour}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?._id?.source}
                </TableCell>
                <TableCell align="center">{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CPanelContainer>
  );
}
