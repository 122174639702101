const accumulateTotals = (data) => {
  return data.reduce((acc, item, index) => {
    // Add the current total to the last item in the accumulator
    // If it's the first item, just use its total
    acc.push(index === 0 ? item.total : acc[index - 1] + item.total);
    return acc;
  }, []);
};

export default accumulateTotals;
