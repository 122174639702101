import {
  Avatar,
  Box,
  Chip,
  Fade,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext, ThemeContext } from "src/context";
import capitalizeWords from "src/libs/capitalizeWords";
import httpClient from "src/libs/httpClient";
import { useSnackbar } from "notistack";
import removeUndefinedFromString from "src/libs/removeUndefinedFromString";
import moment from "moment";

const styles = {
  tableCell: { p: 0, py: 1, pl: 1 },
};

export default function SubscriberTable({
  subscribers,
  getData,
  loading,
  perPage,
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        border: `1px ${theme.borderColor} solid`,
        borderTop: "none",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        mb: 2,
      }}
    >
      {loading ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <div
            style={{
              width: "100%",
              height: 40.5,
              borderRadius: ".25rem",
              backgroundColor: theme.ui,
            }}
          />
          {Array.from(new Array(perPage)).map((item, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                height: 49,
                borderRadius: ".25rem",
                backgroundColor: theme.ui,
              }}
            />
          ))}
        </div>
      ) : (
        <Fade in={!loading}>
          <div>
            {subscribers?.length > 0 ? (
              <Table
                sx={{ minWidth: 650, backgroundColor: theme.ui }}
                aria-label="simple table"
              >
                <TableHead
                // sx={{
                //   "& th": {
                //     borderBottom: `2px ${theme.borderColor} solid`,
                //   },
                // }}
                >
                  <TableRow>
                    <TableCell sx={styles.tableCell}>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.textColor, fontWeight: 600 }}
                      >
                        Email
                      </Typography>
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.textColor, fontWeight: 600 }}
                      >
                        Subscribed At
                      </Typography>
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.textColor, fontWeight: 600 }}
                      >
                        Unsubscribed
                      </Typography>
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCell}>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.textColor, fontWeight: 600 }}
                      >
                        From
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {subscribers.map((row, index) => {
                    return row?.empty ? (
                      <TableRow
                        sx={{
                          backgroundColor: theme.ui,
                          height: 49,
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:nth-child(odd)": {
                            backgroundColor: theme.ui2 + "33",
                          },

                          "& th, & td": {
                            borderBottom: `1px ${theme.borderColor} solid`,
                          },
                        }}
                      >
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                        <TableCell sx={styles.tableCell} />
                      </TableRow>
                    ) : (
                      <TableItem key={index} row={row} getData={getData} />
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  py: 10,
                  px: 2,
                }}
              >
                <Typography align="center" sx={{ color: theme.textColor3 }}>
                  You don&apos;t have any subscribers.
                </Typography>
              </Box>
            )}
          </div>
        </Fade>
      )}
    </TableContainer>
  );
}

const TableItem = ({ row, getData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { theme } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(row?.unsubscribed);

  const handleChangeStatus = (e) => {
    setLoading(true);
    console.log(e);

    if (e?.hasOwnProperty("unsubscribed")) {
      setUnsubscribed(e?.isArchived);
    }

    httpClient()
      .put(`/admin/newslatter/subscriber/${row?._id}`, e)
      .then((res) => {
        console.log(res.data);

        enqueueSnackbar(`Subscribers updated successfully`, {
          variant: "success",
        });

        getData();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          removeUndefinedFromString(err?.response?.data?.message) ||
            `Faild to update subscriber. Please try again.`,
          { variant: "error" }
        );

        if (e?.hasOwnProperty("unsubscribed")) {
          setUnsubscribed(row?.unsubscribed);
        }

        getData();
        setLoading(false);
      });
  };

  return loading ? (
    <TableRow
      sx={{
        backgroundColor: theme.ui2,
        height: 57,
        "&:last-child td, &:last-child th": { border: 0 },
        // "&:nth-child(odd)": { backgroundColor: theme.ui2 },
        "&:nth-child(odd)": {
          backgroundColor: theme.ui2 + "33",
        },

        "& th, & td": {
          borderBottom: `1px ${theme.borderColor} solid`,
        },
      }}
    >
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
      <TableCell sx={styles.tableCell} />
    </TableRow>
  ) : (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:nth-child(odd)": { backgroundColor: theme.ui2 + 33 },
          "& th, & td": {
            borderBottom: `1px ${theme.borderColor} solid`,
          },
        }}
      >
        <TableCell component="th" scope="row" sx={{ ...styles.tableCell }}>
          <a
            href={`mailto:${row.email}?subject=Newsletter&body=Hello, ${row.email},\n\n`}
            target="_blank"
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                color: theme.textColor2,
                padding: 0,
              }}
            >
              {row.email}
            </Typography>
          </a>
        </TableCell>
        <TableCell align="center" sx={styles.tableCell}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              color: theme.textColor2,
              padding: 0,
              width: "100%",
            }}
          >
            {moment(row?.updatedAt).fromNow()}
          </Typography>
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {row?.unsubscribed ? (
            <Chip
              label={`Yes (${moment(row?.unsubscribedAt)?.fromNow()})`}
              sx={{
                backgroundColor: theme.accent.red + 11,
                color: theme.accent.red,
              }}
              onClick={() => {
                handleChangeStatus({ unsubscribed: false });
              }}
            />
          ) : (
            <Chip
              label="No"
              sx={{
                backgroundColor: theme.accent.green + 11,
                color: theme.accent.green,
              }}
              onClick={() => {
                handleChangeStatus({ unsubscribed: true });
              }}
            />
          )}
        </TableCell>

        <TableCell align="center" sx={styles.tableCell}>
          {/* {row?.type} */}
          <Chip
            label={row?.from}
            // sx={{
            // backgroundColor: theme.accent.green + 11,
            // color: theme.accent.green,
            // }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
