import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Fade,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ThemeContext } from "src/context";
import { useContext, useState } from "react";
import TestimonalDialog from "src/dialogs/TestimonalDialog";
import httpClient from "src/libs/httpClient";
import { toast } from "sonner";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const styles = {
  tableCell: { p: 0, py: 1, pl: 1 },
};
let dragStartIndex;
export default function TestimonialsTable({
  testimonal,
  getData,
  loading,
  perPage,
  setTestimonal,
  setHasChangedPosition,
}) {
  const { theme } = useContext(ThemeContext);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(testimonal);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dragStartIndex === result.destination.index
      ? setHasChangedPosition(false)
      : setHasChangedPosition(true);

    setTestimonal(items);
  };
  return (
    <DragDropContext
      onDragStart={(e) => {
        return (dragStartIndex = e.source.index);
      }}
      onDragEnd={onDragEnd}
    >
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: `1px ${theme.borderColor + 22} solid`,
          borderTop: "none",
          // borderTopLeftRadius: 0,
          // borderTopRightRadius: 0,
          mb: 2,
        }}
      >
        {loading ? (
          <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <div
              style={{
                width: "100%",
                height: 40.5,
                borderRadius: ".25rem",
                backgroundColor: theme.ui,
              }}
            />
            {Array.from(new Array(perPage)).map((item, index) => (
              <div
                key={index}
                style={{
                  width: "100%",
                  height: 72.9,
                  borderRadius: ".25rem",
                  backgroundColor: theme.ui,
                }}
              />
            ))}
          </div>
        ) : (
          <Fade in={!loading}>
            <div>
              {testimonal?.length > 0 ? (
                <Table
                  sx={{ minWidth: 650, backgroundColor: theme.ui }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell align="center">Position</TableCell>
                      <TableCell>Message</TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <Droppable droppableId="partnersList">
                    {(provided) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {testimonal?.map((data, index) => (
                          <Draggable
                            key={data?._id}
                            draggableId={data?._id}
                            index={index}
                          >
                            {(provided) => (
                              <TableItem
                                key={index}
                                provided={provided}
                                data={data}
                                getData={getData}
                              />
                              // </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    py: 10,
                    px: 2,
                  }}
                >
                  <Typography align="center" sx={{ color: theme.textColor3 }}>
                    You don&apos;t have any partners.
                  </Typography>
                </Box>
              )}
            </div>
          </Fade>
        )}
      </TableContainer>
    </DragDropContext>
  );
}

const TableItem = ({ data, getData, provided }) => {
  const { theme, bp } = useContext(ThemeContext);

  const [open, setOpen] = useState(false);

  const [isPublicChecked, setIsPublicChecked] = useState(data?.isPublic);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    httpClient()
      .delete(`/admin/testimonials/${data._id}`)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVisibility = (isChecked) => {
    setIsLoading(true);
    toast("Updating testimonal...", { type: "info" });

    httpClient()
      .put(`/admin/testimonials/${data._id}`, { isPublic: isChecked })
      .then((res) => {
        getData();
        setIsPublicChecked((e) => !e);
        setIsLoading(false);
        toast("Testimonial updated", { type: "success" });
      })
      .catch((err) => {
        setIsPublicChecked(false);
        setIsLoading(false);
        toast(err.response.data.message || "Failed to update testimonial", {
          type: "error",
        });
      });
  };

  return (
    <TableRow
      {...provided.draggableProps}
      ref={provided.innerRef}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-child(odd)": {
          backgroundColor: theme.ui2 + "33",
        },

        "& td": {
          borderBottom: `1px ${theme.borderColor} solid`,
        },
        opacity: isPublicChecked ? 1 : 0.6,
      }}
    >
      <>
        <TableCell {...provided.dragHandleProps}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0.8,
            }}
          >
            <DragIndicatorIcon />
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 37.98,
              height: 37.98,
            }}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={() => handleVisibility(!isPublicChecked)}>
                {isPublicChecked ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </IconButton>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
              whiteSpace: !bp?.xl && "nowrap",
            }}
          >
            <Avatar
              src={`${process.env.REACT_APP_CDN_URL}/${data?.user?.avatar}`}
              alt={data?.user?.name}
            />

            {data?.user?.name}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
              whiteSpace: !bp?.xl && "nowrap",
            }}
          >
            <Avatar
              sx={{
                width: 30,
                height: 30,
              }}
              src={`${process.env.REACT_APP_CDN_URL}/${data.company?.logo}`}
              alt={data?.company?.name}
            />

            {data?.company?.name}
          </Box>
        </TableCell>
        <TableCell align="center">{data?.company.position}</TableCell>
        <TableCell>
          <span className="max1Lines">{data?.review}</span>
        </TableCell>

        {/* <TableCell>{data.name}</TableCell> */}
        <TableCell align="right">
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <IconButton onClick={() => setOpen(true)}>
              <EditIcon
                sx={{
                  cursor: "pointer",
                  color: theme.buttonColor,
                  // mr: 1,
                  display: "block",
                }}
              />
            </IconButton>

            <IconButton onClick={() => handleDelete()}>
              <DeleteIcon
                sx={{
                  display: "block",
                  cursor: "pointer",
                  color: theme.accent.red,
                }}
              />
            </IconButton>
          </Box>
        </TableCell>
      </>
      {open && (
        <TestimonalDialog
          open={open}
          setOpen={setOpen}
          data={data}
          getData={getData}
        />
      )}
    </TableRow>
  );
};
