import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Bar, Line } from "react-chartjs-2";

import httpClient from "src/libs/httpClient";

import { ThemeContext } from "src/context";
import CPanelContainer from "src/components/CPanelContainer";
import InfoCard from "src/components/InfoCard";
import PDDateRangePicker from "src/components/_pdStyle/PDDateRangePicker";
import LoadingPage from "src/components/LoadingPage";
import BentoProjectDetailsDialog from "src/dialogs/BentoProjectDetailsDialog";

function getCumulativeCounts(items) {
  const cumulativeCounts = [];
  items.reduce((accumulator, item) => {
    const newTotal = accumulator + item;
    cumulativeCounts.push(newTotal);
    return newTotal;
  }, 0);
  return cumulativeCounts;
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Jutsu's Monthly Project Analysis",
    },
  },
};

export default function BentoProjectAnalysesPage() {
  const { theme, bp } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [summary, setSummary] = useState();

  const [showBentoProjectDetailsDialog, setShowBentoProjectDetailsDialog] =
    useState(false);

  const [topContributors, setTopContributors] = useState([]);

  const [grouping, setGrouping] = useState("daily"); // ['daily', 'weekly', 'monthly'

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  useEffect(() => {
    getData();
    getTopContributors();
  }, [startDate, endDate, grouping]);

  const getData = async () => {
    httpClient()
      .get(
        `/admin/analysis/bento/projects?startDate=${startDate}&endDate=${endDate}&grouping=${grouping}`
      )
      .then((res) => {
        const data = res.data;
        console.log(data);

        const chartLabels = data.map((entry) => entry.date);
        const chartCounts = data.map((entry) => entry.count);

        setSummary({
          totalProjects: data.reduce((acc, curr) => acc + curr.count, 0),
          highestGrossingMonth: data.reduce((acc, curr) =>
            acc.count > curr.count ? acc : curr
          ),
        });

        const chartColors = theme.buttonColor;

        setChartData({
          labels: chartLabels,
          datasets: [
            {
              label: "Number of Projects Created",
              data: chartCounts,
              fill: false,
              borderColor: chartColors, // Apply generated colors to borders
              backgroundColor: chartColors, // Apply generated colors to backgrounds
              tension: 0.1,
            },
          ],
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getTopContributors = async () => {
    httpClient()
      .get(
        `/admin/analysis/bento/top-contributors?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        const data = res.data;
        console.log(data);
        setTopContributors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <>
      <CPanelContainer title="Project Analysis" sx={{ p: 1 }}>
        <Box
          sx={{
            backgroundColor: theme.ui,
            color: theme.textColor,
            padding: 2,
            borderRadius: 2,
            gap: 1,

            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <ButtonGroup
              aria-label="outlined primary button group"
              onClick={(e) => {
                setGrouping(e.target.value);
                console.log(e.target.value);
              }}
            >
              <Button
                value="daily"
                sx={{
                  backgroundColor:
                    grouping === "daily"
                      ? theme.buttonColor
                      : theme.buttonColor + 33,
                  color:
                    grouping === "daily"
                      ? theme.buttonTextColor
                      : theme.textColor2,
                }}
              >
                Daily
              </Button>
              <Button
                value="weekly"
                sx={{
                  backgroundColor:
                    grouping === "weekly"
                      ? theme.buttonColor
                      : theme.buttonColor + 33,
                  color:
                    grouping === "weekly"
                      ? theme.buttonTextColor
                      : theme.textColor2,
                }}
              >
                Weekly
              </Button>
              <Button
                value="monthly"
                sx={{
                  backgroundColor:
                    grouping === "monthly"
                      ? theme.buttonColor
                      : theme.buttonColor + 33,
                  color:
                    grouping === "monthly"
                      ? theme.buttonTextColor
                      : theme.textColor2,
                }}
              >
                Monthly
              </Button>
            </ButtonGroup>
          </Box>

          <Box>
            <PDDateRangePicker
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: 2,
          }}
        >
          <InfoCard
            title={`Total Projects`}
            value={summary?.totalProjects?.toLocaleString()}
            subTitle="Total number of users created in the selected period."
          />

          <InfoCard
            title={`Highest Grossing`}
            value={summary?.highestGrossingMonth?.count?.toLocaleString()}
            subTitle="The single Day/Week/Month with the highest number of users created."
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: bp?.md ? "1fr" : "1fr 1fr",
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Line
              color="red"
              key="yearly-user-analyses-line"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "600px",
                backgroundColor: theme.ui,
                color: theme.textColor,
                borderRadius: 8,
                padding: "24px 16px",
              }}
              options={options}
              data={{
                labels: chartData?.labels,
                datasets: [
                  {
                    ...chartData?.datasets[0],
                    data:
                      chartData?.datasets?.length > 0
                        ? chartData?.datasets[0]?.data
                        : [],
                    // data:
                    //   dataType === "regular"
                    //     ? chartData?.datasets?.length > 0
                    //       ? chartData?.datasets[0]?.data
                    //       : []
                    //     : chartData?.datasets?.length > 0
                    //     ? getCumulativeCounts(chartData?.datasets[0]?.data)
                    //     : [],
                  },
                ],
              }}
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            <Bar
              key="yearly-user-analyses-line"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "600px",
                backgroundColor: theme.ui,
                color: theme.textColor,
                borderRadius: 8,
                padding: "24px 16px",
              }}
              options={options}
              data={{
                labels: chartData?.labels,
                datasets: [
                  {
                    ...chartData?.datasets[0],
                    data:
                      chartData?.datasets?.length > 0
                        ? getCumulativeCounts(chartData?.datasets[0]?.data)
                        : [],
                  },
                ],
              }}
            />
          </Box>
        </Box>

        <TableContainer
          sx={{ backgroundColor: theme.ui, color: theme.textColor }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell align="center">Projects Created</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {topContributors.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => setShowBentoProjectDetailsDialog(row)}
                >
                  <TableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={row.createdBy.avatar}
                        alt={row.createdBy.userName || row.createdBy.name}
                      />

                      <p>
                        {row.createdBy.userName ||
                          row.createdBy.name ||
                          row.createdBy.email}
                      </p>
                    </Box>
                  </TableCell>

                  <TableCell align="center">{row.totalProjects}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CPanelContainer>

      <BentoProjectDetailsDialog
        open={showBentoProjectDetailsDialog}
        setOpen={setShowBentoProjectDetailsDialog}
      />
    </>
  );
}
