import {
  Avatar,
  Box,
  Button,
  Chip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CPanelContainer from "src/components/CPanelContainer";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import BentoTemplateEditDialog from "src/dialogs/BentoTemplateEditDialog";

export default function BentoTemplatePage() {
  const { theme } = useContext(ThemeContext);

  const { templateId } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    httpClient(process.env.REACT_APP_COPILOT_URL)
      .get(`/craft/template/${templateId}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <CPanelContainer title="Bento Featured" sx={{ p: 1 }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <img
            src={data?.image}
            alt={data?.name}
            style={{ width: 250, height: "auto" }}
          />

          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography variant="h5">{data?.name}</Typography>
            <Typography variant="body1">{data?.description}</Typography>

            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {data?.tags?.map((tag, index) => (
                <Chip key={index} variant="outlined" size="small" label={tag} />
              ))}
            </Box>

            <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
              <Button variant="contained" color="primary">
                Edit
              </Button>
              <Button variant="contained" color="error">
                Delete
              </Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
          <Avatar src={data?.createdBy?.avatar} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
            }}
          >
            <Typography variant="subtitle1">{data?.createdBy?.name}</Typography>
            <Typography variant="caption">
              {data?.createdBy?.userName}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: 1,
            mt: 8,
          }}
        >
          <h2>Components</h2>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
              width: "100%",
            }}
          >
            <TableContainer
              sx={{ backgroundColor: theme.ui, color: theme.textColor }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="center">Links</TableCell>
                    {/* <TableCell align="center">Style</TableCell>
                    <TableCell align="center">Props</TableCell> */}
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.components?.map((component, index) => (
                    <Item key={index} component={component} getData={getData} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </CPanelContainer>
  );
}

const Item = ({ component, getData }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        {/* {JSON.stringify(component, null, 40)} */}

        <TableCell>
          <Typography variant="body1">{component.name}</Typography>
        </TableCell>

        <TableCell align="center">
          {component.links?.dev && (
            <a variant="body2" href={component.links?.dev}>
              Dev
            </a>
          )}

          {component.links?.prod && (
            <a
              style={{ marginLeft: 16, marginRight: 16 }}
              variant="body2"
              href={component.links?.prod}
            >
              Prod
            </a>
          )}

          {component.links?.near && (
            <a variant="body2" href={component.links?.near}>
              Near
            </a>
          )}
        </TableCell>

        {/* <TableCell>
          {Object.keys(component.style).map((key, index) => (
            <Typography key={index} variant="body2">
              {`${key}: ${component.style[key]}`}
            </Typography>
          ))}
        </TableCell>

        <TableCell>
          {Object.keys(component.props).map((key, index) => (
            <Typography key={index} variant="body2">
              {`${JSON.stringify(
                key,
                null,
                4
              )} : ${JSON.stringify(
                component.props[key],
                null,
                4
              )}`}
            </Typography>
          ))}
        </TableCell> */}

        <TableCell align="right" sx={{ display: "flex", gap: 2 }}>
          <IconButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            <EditRoundedIcon />
          </IconButton>

          <IconButton size="small" variant="contained" color="error">
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <BentoTemplateEditDialog
        open={open}
        setOpen={setOpen}
        component={component}
        getData={getData}
      />
    </>
  );
};
