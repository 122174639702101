import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useContext, useState } from "react";
import PDButton from "src/components/_pdStyle/PDButton";
import StarIcon from "@mui/icons-material/Star";
import getTimeAgo from "src/libs/getTimeAgo";
import { toast } from "sonner";
import { ThemeContext } from "src/context";
import httpClient from "src/libs/httpClient";

export default function BentoProjectItems({ item, getData, handleDelete }) {
  const { theme } = useContext(ThemeContext);
  console.log(item);
  const [loading, setLoading] = useState(false);

  const handleClick = ({ id, isFeatured }) => {
    setLoading(true);
    httpClient()
      .put(`/admin/bento/projects/${id}`, { isFeatured: !isFeatured })
      .then((res) => {
        console.log("res.data: ", res.data);
        getData();
        setLoading(false);
        toast("Featured updated.", { type: "success" });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast("Featured not updated.", { type: "error" });
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          cursor: "pointer",
          position: "relative",
          "& *": {
            transition: "all 0.2s ease-in-out",
          },
          "&:hover": {
            "& .is-featured": {
              opacity: 1,
            },
            "& img": {
              transform: "scale(1.01)",
              boxShadow: "0 0 10px rgba(255,255,255, 0.6)",
            },
          },
        }}
      >
        <Box
          onClick={() =>
            window.open(
              `https://bento.jutsu.ai/preview/${item?._id}/${item?.latest?._id}`,
              "_blank"
            )
          }
        >
          <img
            src={item?.latest?.thumbnail}
            style={{
              aspectRatio: "16/9",
              width: "100%",
              borderRadius: 8,

              objectFit: "cover",
            }}
            alt="project thumbnail"
          />
        </Box>
        {item?.isFeatured && (
          <Box
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              backgroundColor: "#faaf00",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 0.5,
              pl: 1,
              gap: 0.2,
              borderRadius: 2,
            }}
          >
            <Typography variant="p">Featured</Typography>
            <StarIcon fontSize="small" />
          </Box>
        )}
        {loading ? (
          <CircularProgress
            size={20}
            sx={{ position: "absolute", bottom: "46%", right: "48%" }}
          />
        ) : (
          <>
            <PDButton
              onClick={() =>
                handleClick({
                  id: item?._id,
                  isFeatured: item?.isFeatured,
                })
              }
              className="is-featured"
              sx={{
                width: "fit-content",
                fontWeight: 500,
                fontSize: 12,
                position: "absolute",
                bottom: "42%",
                left: "15%",
                px: 2,
                py: 0.5,
                borderRadius: 2,
                opacity: 0,
                backgroundColor: theme.ui2 + 80,
                zIndex: 9999,
                "&:hover": {
                  backgroundColor: item?.isFeatured
                    ? theme.accent.red
                    : theme.buttonColor,
                },
              }}
            >
              {item?.isFeatured ? "Remove Featured" : "Add Featured"}
            </PDButton>
            <PDButton
              onClick={() => handleDelete(item?._id)}
              className="is-featured"
              sx={{
                width: "fit-content",
                fontWeight: 500,
                fontSize: 12,
                position: "absolute",
                bottom: "42%",
                right: "15%",
                px: 2,
                py: 0.5,
                borderRadius: 2,
                opacity: 0,
                backgroundColor: theme.ui2 + 80,
                zIndex: 9999,
                "&:hover": {
                  backgroundColor: item?.isFeatured
                    ? theme.accent.red
                    : theme.buttonColor,
                },
              }}
            >
              Delete File
            </PDButton>
          </>
        )}
        <Typography
          variant="p2"
          sx={{
            fontWeight: 400,

            position: "absolute",
            bottom: 16,
            right: 8,

            zIndex: 100,

            color: theme.textColor,
            backgroundColor: theme.backgroundColor + 33,
            padding: "4px 8px",
            borderRadius: 1.5,
            backdropFilter: "blur(6px)",
          }}
        >
          {getTimeAgo(item?.updatedAt)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1,
        }}
      >
        <Avatar sx={{ height: 30, width: 30 }} src={item?.createdBy?.avatar} />
        <Tooltip title={item?.latest?.prompt} arrow>
          <Typography
            className="max1Lines"
            sx={{
              fontWeight: 500,
              color: theme.textColor2,
              backgroundColor: theme.ui2,
              px: 1.5,
              py: 0.5,
              borderRadius: 4,
              cursor: "pointer",
            }}
            variant="h6"
          >
            {item?.latest?.prompt}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
}
