import React from "react";
import MyRouter from "./Router";
import { theme } from "src/context/ThemeContext";
import { ThemeContextProvider, AuthContextProvider } from "src/context";

import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { CookiesProvider } from "react-cookie";
import { Toaster } from "sonner";

export default function App(props) {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ThemeContextProvider>
            <AuthContextProvider>
              <MyRouter />

              <Toaster />
            </AuthContextProvider>
          </ThemeContextProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}
