import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";
import httpClient from "src/libs/httpClient";
import LoadingPage from "src/components/LoadingPage";

export default function BentoTemplateEditDialog({
  open,
  setOpen,
  component,
  getData,
}) {
  const { theme } = useContext(ThemeContext);

  const [data, setData] = useState(JSON.stringify(component, null, 2));

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      JSON.parse(data);
    } catch (err) {
      console.log(err);
      alert("Invalid JSON");
      return;
    }

    setIsLoading(true);
    httpClient(process.env.REACT_APP_COPILOT_URL)
      .put(`/craft/template/components/${component._id}`, JSON.parse(data))
      .then((res) => {
        console.log(res.data);
        setOpen((e) => !e);
        setIsLoading(false);
        getData();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="md"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          backgroundImage: "none",
          "& .MuiPaper-root": {
            backgroundImage: "none",
            backgroundColor: theme.backgroundColor,
          },
        },
      }}
    >
      {isLoading && <LoadingPage open={isLoading} />}

      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        Edit
      </DialogTitle>

      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        <textarea
          rows="40"
          cols="50"
          placeholder="Description"
          value={data}
          onChange={(e) => setData(e.target.value)}
        />
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        <PDButton
          onClick={() => {
            setOpen((e) => !e);
          }}
        >
          Cancel
        </PDButton>

        <PDButton
          sx={{
            color: "#fcf5f7",
            backgroundColor: "#bc002d",
          }}
          type="submit"
          onClick={handleSubmit}
        >
          Save
        </PDButton>
      </DialogActions>
    </Dialog>
  );
}
