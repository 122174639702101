import CPanelContainer from "src/components/CPanelContainer";
import PDPagiation from "src/components/_pdStyle/PDPagiation";
import UsersTable from "./_components/UsersTable";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context";
import { useDebounce } from "use-debounce";
import { Box } from "@mui/material";
import PDInput from "src/components/_pdStyle/PDInput";
import PDButton from "src/components/_pdStyle/PDButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import httpClient from "src/libs/httpClient";
import { stringify } from "querystring";

export default function UsersPage() {
  const { theme } = useContext(ThemeContext);

  const [showSetupStoreDialog, setShowSetupStoreDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [search, setSearch] = useState("");
  const [searchDebounce] = useDebounce(search, 500);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [page, perPage, searchDebounce]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = () => {
    const query = {
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        search: searchDebounce !== undefined ? searchDebounce : "",
      }),
    };

    httpClient()
      .get(`/users?${stringify(query)}`)
      .then((res) => {
        let data = res?.data?.result || [];
        console.log(res?.data);
        if (data.length > 0) {
          while (data.length < perPage) {
            data.push({ empty: true });
          }
        }

        setUsers(data);
        setPageCount(Math.ceil(res.data?.count / perPage));
        setTotalCount(res.data?.count);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <CPanelContainer title="Users">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <PDInput
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ backgroundColor: theme.ui }}
        />

        <PDButton
          disabled
          sx={{ maxWidth: 150, backgroundColor: theme.borderColor }}
          startIcon={<AddRoundedIcon />}
          onClick={() => setShowSetupStoreDialog((e) => !e)}
        >
          Add User
        </PDButton>
      </Box>

      <UsersTable
        users={users}
        loading={loading}
        getData={getData}
        perPage={perPage}
      />
      <PDPagiation
        pageCount={pageCount}
        totalCount={totalCount}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </CPanelContainer>
  );
}
