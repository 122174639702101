import { useContext, useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Divider } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";

import httpClient from "src/libs/httpClient";

import { ThemeContext } from "src/context";
import CPanelContainer from "src/components/CPanelContainer";
import InfoCard from "src/components/InfoCard";
import PDDateRangePicker from "src/components/_pdStyle/PDDateRangePicker";
import LoadingPage from "src/components/LoadingPage";

function getCumulativeCounts(items) {
  const cumulativeCounts = [];
  items.reduce((accumulator, item) => {
    const newTotal = accumulator + item;
    cumulativeCounts.push(newTotal);
    return newTotal;
  }, 0);
  return cumulativeCounts;
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Jutsu's Monthly User Analysis",
    },
  },
};

export default function PullRequestsAnalysesPage() {
  const { theme, bp } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [summary, setSummary] = useState();

  const [grouping, setGrouping] = useState("daily"); // ['daily', 'weekly', 'monthly'
  const [dataType, setDataType] = useState("cumulative"); // ['regular', 'cumulative']

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );

  useEffect(() => {
    getData();
  }, [startDate, endDate, grouping]);

  const getData = async () => {
    httpClient()
      .get(
        `/admin/analysis/pullRequests?startDate=${startDate}&endDate=${endDate}&grouping=${grouping}`
      )
      .then((res) => {
        const data = res.data;
        console.log(data);

        const chartLabels = data.map((entry) => entry.date);
        const chartCounts = data.map((entry) => entry.count);

        setSummary({
          totalUsers: data.reduce((acc, curr) => acc + curr.count, 0),
          highestGrossingMonth: data.reduce((acc, curr) =>
            acc.count > curr.count ? acc : curr
          ),
        });

        const chartColors = theme.buttonColor;

        setChartData({
          labels: chartLabels,
          datasets: [
            {
              label: "Number of Users Created",
              data: chartCounts,
              fill: false,
              borderColor: chartColors, // Apply generated colors to borders
              backgroundColor: chartColors, // Apply generated colors to backgrounds
              tension: 0.1,
            },
          ],
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <CPanelContainer title="Pull Requests" sx={{ p: 1 }}>
      <Box
        sx={{
          backgroundColor: theme.ui,
          color: theme.textColor,
          padding: 2,
          borderRadius: 2,
          gap: 1,

          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <ButtonGroup
            aria-label="outlined primary button group"
            onClick={(e) => {
              setGrouping(e.target.value);
              console.log(e.target.value);
            }}
          >
            <Button
              value="daily"
              sx={{
                backgroundColor:
                  grouping === "daily"
                    ? theme.buttonColor
                    : theme.buttonColor + 33,
                color:
                  grouping === "daily"
                    ? theme.buttonTextColor
                    : theme.textColor2,
              }}
            >
              Daily
            </Button>
            <Button
              value="weekly"
              sx={{
                backgroundColor:
                  grouping === "weekly"
                    ? theme.buttonColor
                    : theme.buttonColor + 33,
                color:
                  grouping === "weekly"
                    ? theme.buttonTextColor
                    : theme.textColor2,
              }}
            >
              Weekly
            </Button>
            <Button
              value="monthly"
              sx={{
                backgroundColor:
                  grouping === "monthly"
                    ? theme.buttonColor
                    : theme.buttonColor + 33,
                color:
                  grouping === "monthly"
                    ? theme.buttonTextColor
                    : theme.textColor2,
              }}
            >
              Monthly
            </Button>
          </ButtonGroup>

          {/* <Divider orientation="vertical" />

          <ButtonGroup
            aria-label="outlined primary button group"
            onClick={(e) => {
              setDataType(e.target.value);
            }}
          >
            <Button
              value="regular"
              sx={{
                backgroundColor:
                  dataType === "regular"
                    ? theme.buttonColor
                    : theme.buttonColor + 33,
                color:
                  dataType === "regular"
                    ? theme.buttonTextColor
                    : theme.textColor2,
              }}
            >
              Regular
            </Button>
            <Button
              value="cumulative"
              sx={{
                backgroundColor:
                  dataType === "cumulative"
                    ? theme.buttonColor
                    : theme.buttonColor + 33,
                color:
                  dataType === "cumulative"
                    ? theme.buttonTextColor
                    : theme.textColor2,
              }}
            >
              Cumulative
            </Button>
          </ButtonGroup> */}
        </Box>

        <Box>
          <PDDateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: 2,
        }}
      >
        <InfoCard
          title={`Total Users`}
          value={summary?.totalUsers?.toLocaleString()}
          subTitle="Total number of users created in the selected period."
        />

        <InfoCard
          title={`Highest Grossing`}
          value={summary?.highestGrossingMonth?.count?.toLocaleString()}
          subTitle="The single Day/Week/Month with the highest number of users created."
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: bp?.md ? "1fr" : "1fr 1fr",
          gap: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Line
            color="red"
            key="yearly-user-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "600px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={{
              labels: chartData?.labels,
              datasets: [
                {
                  ...chartData?.datasets[0],
                  data:
                    chartData?.datasets?.length > 0
                      ? chartData?.datasets[0]?.data
                      : [],
                  // dataType === "regular"
                  //   ? chartData?.datasets?.length > 0
                  //     ? chartData?.datasets[0]?.data
                  //     : []
                  //   : chartData?.datasets?.length > 0
                  //   ? getCumulativeCounts(chartData?.datasets[0]?.data)
                  //   : [],
                },
              ],
            }}
          />
        </Box>

        <Box sx={{ width: "100%" }}>
          <Bar
            key="yearly-user-analyses-line"
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "600px",
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 8,
              padding: "24px 16px",
            }}
            options={options}
            data={{
              labels: chartData?.labels,
              datasets: [
                {
                  ...chartData?.datasets[0],
                  data:
                    chartData?.datasets?.length > 0
                      ? getCumulativeCounts(chartData?.datasets[0]?.data)
                      : [],
                },
              ],
            }}
          />
        </Box>
      </Box>
    </CPanelContainer>
  );
}
