import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "src/context";
import PDButton from "src/components/_pdStyle/PDButton";

export default function ActionDialog({
  open,
  setOpen,

  title,
  content,
  action,
  description,

  primaryButtonOnClick,
  primaryButtonLabel,
  primaryButtonLoading,
  primaryButtonType,

  secondaryButtonOnClick,
  secondaryButtonLabel,
  secondaryButtonLoading,

  dialogProps,
}) {
  const { theme } = useContext(ThemeContext);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen((e) => !e)}
      maxWidth="xs"
      fullWidth
      scroll="body"
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiDialog-container": {
          backgroundImage: "none",
          "& .MuiPaper-root": {
            backgroundImage: "none",
            backgroundColor: theme.backgroundColor,
          },
        },
      }}
      {...dialogProps}
    >
      <DialogTitle
        variant="h4"
        fontWeight={600}
        sx={{ color: theme.textColor, px: 2, pt: 2, pb: 3 }}
      >
        {title || "title"}
      </DialogTitle>
      <DialogContent
        sx={{ p: 2, display: "flex", flexDirection: "column", gap: 3 }}
      >
        {content ? (
          content
        ) : (
          <Typography
            variant="h5"
            fontWeight={400}
            sx={{ color: theme.textColor2 }}
          >
            {description || "description"}
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
          margin: 0,
        }}
      >
        {action || (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 2,
              width: "100%",
            }}
          >
            <PDButton
              loading={secondaryButtonLoading}
              onClick={() => {
                secondaryButtonOnClick
                  ? secondaryButtonOnClick()
                  : setOpen((e) => !e);
              }}
            >
              {secondaryButtonLabel || "Cancel"}
            </PDButton>
            <PDButton
              sx={{
                color: "#fcf5f7",
                backgroundColor: "#bc002d",
              }}
              disabled={primaryButtonLoading}
              loading={primaryButtonLoading}
              type={primaryButtonType || "submit"}
              onClick={() => {
                if (primaryButtonOnClick) primaryButtonOnClick();

                // primaryButtonOnClick ? primaryButtonOnClick() : () => {};
              }}
            >
              {primaryButtonLabel || "Save"}
            </PDButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}
