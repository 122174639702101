import { useContext, useEffect, useState } from "react";
import CPanelContainer from "src/components/CPanelContainer";

import httpClient from "src/libs/httpClient";
import { ThemeContext } from "src/context";
import StraightRoundedIcon from "@mui/icons-material/StraightRounded";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import ActionDialog from "src/dialogs/ActionDialog";
import InfoCard from "src/components/InfoCard";

const hideList = ["nearpad.testnet", "nearcon23.near"];

export default function CountPerUserPage() {
  const { theme } = useContext(ThemeContext);

  const [selectedUser, setSelectedUser] = useState(null);

  const [loading, setLoading] = useState(true);

  const [row, setRow] = useState(0);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState();

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getData();

    getSummary();
  }, []);

  const getData = async (sort) => {
    console.log("SORT : ", sort);
    setLoading(true);
    httpClient()
      .post("/admin/analysis/components/count-per-user", { sort })
      .then((res) => {
        const data = res.data;

        console.log(data);

        let totalCount = data?.reduce((sum, item) => sum + item.count, 0);
        console.log(totalCount);

        setData(data);
        setRow(data);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getSummary = () => {
    httpClient()
      .get("/admin/analysis/components/summary")
      .then((res) => {
        const data = res.data;

        console.log(data);
        setSummary(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // desc or asc
  const resort = (data, asc) => {
    console.log({ data, asc });
    if (data === "lastCreated") {
      console.log("Is Last Created");
      getData({ latestCreatedAt: asc ? 1 : -1 });
      // setData((e) =>
      //   asc
      //     ? row.sort(
      //         (a, b) => new Date(a.lastCreated) - new Date(b.lastCreated)
      //       )
      //     : row.sort(
      //         (a, b) => new Date(b.lastCreated) - new Date(a.lastCreated)
      //       )
      // );
    } else if (data === "count") {
      console.log("Is Count");
      getData({ totalVersionCount: asc ? 1 : -1 });
      // setData(
      //   asc
      //     ? row.sort((a, b) => a.count - b.count)
      //     : row.sort((a, b) => b.count - a.count)
      // );
    } else if (data === "buildCount") {
      console.log("Is Build Count");
      getData({ uniqueComponentsCreated: asc ? 1 : -1 });
      // setData(
      //   asc
      //     ? row.sort((a, b) => a.components.length - b.components.length)
      //     : row.sort((a, b) => b.components.length - a.components.length)
      // );
    }
    setRefresh(Math.random());
  };

  return (
    <>
      <CPanelContainer
        title="Count Per User"
        subTitle="Count Per User of Components and Builds"
        sx={{ p: 1 }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box
          sx={{
            backgroundColor: theme.ui,
            color: theme.textColor,
            padding: 2,
            borderRadius: 2,
            gap: 1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            for="monthYear"
            variant="h6"
            sx={{ color: theme.textColor3, fontWeight: 500 }}
          >
            Search
          </Typography>
          <input
            style={{
              border: `1px solid ${theme.borderColor}`,
              borderRadius: 2,
              color: theme.textColor2,

              backgroundColor: theme.backgroundColor,

              height: 56,
              paddingInline: 16,
              borderRadius: 8,
            }}
            // value={monthSelect}
            // onChange={(e) => {
            //   const [year, month, day] = e.target.value.split("-");
            //   console.log({ year, month, day });
            //   setMonthSelect(e.target.value);
            //   getData(year, month, day);
            // }}
          />
        </Box>

        {/* Top Datas */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: 2,
          }}
        >
          {/* <InfoCard
          title="Real Time Components"
          subTitle="Real time components created last hour"
          value={0}
        /> */}

          <InfoCard
            title={`Total Deployed`}
            // value={item1.reduce((acc, item) => acc + item, 0).toLocaleString()}
            value={summary?.uniqueComponentsCount || 0}
          />

          <InfoCard
            title={`Total Commponents`}
            value={summary?.totalComponents || 0}
          />

          <InfoCard
            title={`Last Created Component`}
            value={moment(summary?.lastComponentCreated?.createAt).fromNow()}
          />

          <InfoCard
            title={`Most Deploy By`}
            value={
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <h4 style={{ fontSize: 24, fontWeight: 600 }}>
                  {summary?.mostComponentsUser?.userDetails?.userName || 0}
                </h4>
                <p style={{ fontSize: 16, fontWeight: 500 }}>
                  ({summary?.mostComponentsUser?.count || 0})
                </p>
              </Box>
            }
          />
        </Box>

        <TableContainer
          sx={{
            backgroundColor: theme.ui,
            color: theme.textColor,
            borderRadius: 2,
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ color: theme.textColor }}>
                <TableCell sx={{ color: theme.textColor }}>User</TableCell>

                <TableCellItem
                  type="lastCreated"
                  resort={resort}
                  title="Last Created"
                />

                <TableCellItem
                  type="count"
                  resort={resort}
                  title="Total Builds"
                />

                <TableCellItem
                  type="buildCount"
                  resort={resort}
                  title="Unique Components"
                />
              </TableRow>
            </TableHead>
            <TableBody key={refresh}>
              {data?.map((item, index) => {
                if (hideList.includes(item?.user?.userName)) {
                  return null;
                }

                return (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: "pointer",
                      "&:nth-of-type(odd)": {
                        backgroundColor: theme.textColor + "11",
                      },
                    }}
                    onClick={() => {
                      setSelectedUser(item);
                    }}
                  >
                    <TableCell sx={{ border: "none" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar src={item?.user?.avatar} />

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 1,
                            color: theme.textColor2,
                          }}
                        >
                          <Typography variant="p1" sx={{ ml: 1 }}>
                            {item?.user?.name}
                          </Typography>

                          <Tooltip title={`ID: ${item?.user?._id}`} arrow>
                            <Typography variant="p1" sx={{ ml: 1 }}>
                              {item?.user?.userName || item?.user?.email}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "center",
                        color: theme.textColor2,
                      }}
                    >
                      {moment(
                        item?.latestComponent?.createAt ||
                          item?.lastCreated ||
                          item?.latestCreatedAt
                      ).fromNow()}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "center",
                        color: theme.textColor2,
                      }}
                    >
                      {item?.totalVersionCount}
                      {/* {item?.components?.reduce(
                      (acc, source) => acc + source?.versionCount,
                      0
                    )} */}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "center",
                        color: theme.textColor2,
                      }}
                    >
                      {/* {item?.components?.length || 0} */}
                      {item?.uniqueComponentsCreated || 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CPanelContainer>

      <ActionDialog
        open={selectedUser}
        setOpen={setSelectedUser}
        dialogProps={{
          maxWidth: "md",
        }}
        title={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={selectedUser?.user?.avatar} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: 1,
              }}
            >
              <Typography variant="p1" sx={{ ml: 1 }}>
                {selectedUser?.user?.name}
              </Typography>
              <Typography variant="p1" sx={{ ml: 1 }}>
                {selectedUser?.user?.userName || selectedUser?.user?.email}
              </Typography>
            </Box>
          </Box>
        }
        content={
          <TableContainer
            sx={{
              backgroundColor: theme.ui,
              color: theme.textColor,
              borderRadius: 2,
            }}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ color: theme.textColor }}>
                  <TableCell
                    sx={{ textAlign: "left", color: theme.textColor2 }}
                  >
                    Components
                  </TableCell>

                  <TableCell
                    sx={{ textAlign: "center", color: theme.textColor2 }}
                  >
                    Builds
                  </TableCell>

                  <TableCell
                    sx={{ textAlign: "right", color: theme.textColor2 }}
                  >
                    Latest Build
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedUser?.components?.map((component, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: theme.ui2 + "33",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                        color: theme.textColor2,
                      }}
                      component="a"
                      href={`https://app.jutsu.ai/view/${component?.source}`}
                      target="_blank"
                    >
                      {component?.source}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "center",
                        color: theme.textColor2,
                      }}
                    >
                      {/* {component.totalBuildCount || 0} */}
                      {component.versionCount || 0}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "right",
                        color: theme.textColor2,
                      }}
                    >
                      {moment(component.latestCreatedAt).fromNow()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        action={<div />}
      />
    </>
  );
}

const TableCellItem = ({ title, type, resort }) => {
  const { theme } = useContext(ThemeContext);

  const [asc, setAsc] = useState(true);

  const onClick = () => {
    resort(type, asc);
    setAsc(!asc);
  };

  return (
    <TableCell onClick={() => onClick()}>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",

          justifyContent: "center",
          gap: 1,
          cursor: "pointer",
          color: theme.textColor,
        }}
      >
        {title}
        <StraightRoundedIcon
          sx={{
            fontSize: "1rem",
            rotate: asc ? "0deg" : "180deg",
          }}
        />
      </Box>
    </TableCell>
  );
};
